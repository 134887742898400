import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AppRoutes } from "./routes/appRoutes";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./providers/auth";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { AlertProvider } from "./providers/alert";
import { DialogProvider } from "./providers/dialog";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <DialogProvider>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </DialogProvider>
          </AlertProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
