import {
  Button,
  Divider,
  ExtendList,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListTypeMap,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import { MessageDtoResponse } from "../../../../hooks/api/Appointment/interfaces/appointmentMessage.response";
import { KeyboardEvent, useEffect, useRef } from "react";
import { Close, Send } from "@mui/icons-material";

export const MessageAppointmentUI = ({
  messages,
  handleSendMessage,
  userIdLogged,
  newMessage,
  setNewMessage,
  findAndOpenLink,
  toggleMessages,
  handleKeyDown,
}: {
  newMessage: string;
  setNewMessage: (message: string) => void;
  messages: MessageDtoResponse[];
  handleSendMessage: () => Promise<void>;
  userIdLogged: number;
  findAndOpenLink: (message: string) => void;
  toggleMessages: () => void;
  handleKeyDown: (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) => {
  const { palette } = useTheme();

  const flatlist = useRef<any>();

  useEffect(() => {
    flatlist.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <div style={{ position: "absolute", top: 18, right: 8, zIndex: 101 }}>
        <IconButton onClick={toggleMessages}>
          <Close />
        </IconButton>
      </div>
      <div
        ref={flatlist}
        style={{
          padding: 0,
          width: "100%",
          paddingRight: 10,
          paddingBottom: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {messages.map((message) => (
          <div
            key={message.id}
            style={{
              display: "flex",
              flexDirection:
                message.user.id === userIdLogged ? "row-reverse" : "row",
              marginTop: 5,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
              marginLeft: 0,
              marginRight: 0,
              backgroundColor:
                message.user.id === userIdLogged ? "#0A6172" : "#ddeeff",
              borderRadius: 10,
              justifyContent:
                message.user.id === userIdLogged ? "flex-start" : "flex-end",
              width: "fit-content",
              alignSelf: userIdLogged ? "flex-end" : "flex-start",
            }}
            onClick={() => findAndOpenLink(message.text)}
          >
            <p
              style={{
                fontWeight: "400",
                fontSize: 14,
                color: message.user.id === userIdLogged ? "#ffffff" : "#0A6172",
              }}
            >
              {message.text}
            </p>
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: 10,
          marginTop: 10,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: "10px" }}
            aria-label="directions"
            onClick={handleSendMessage}
          >
            <Send />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
};
