import {
  convertToNumberOrUndefined,
  transformStringToNumberOrUndefined,
} from "../../../utils/stringUtils";
import yup from "../../../utils/yup";

export const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  // password: yup.string().required(),
  // confirmPassword: yup
  //   .string()
  //   .required()
  //   .oneOf([yup.ref("password")], "Senhas não conferem"),
  income: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .positive()
    .required(),
  birthdate: yup.string().required(),
  city: yup.string().required(),
  gender: yup.string().oneOf(["male", "female", "other"]).required(),
  labor: yup.string().required(),
  region: yup
    .string()
    .oneOf(["southeast", "south", "centerwest", "north", "northeast"])
    .required(),
  state: yup.string().required(),
  susNumber: yup.string().required(),
});
