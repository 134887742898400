import { ReactElement } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { ApplicationRoutes } from "../utils/navigation/applicationRoutes";
import { UserType } from "../hooks/api/Login/interfaces/userInfo.response";
import { Button } from "@mui/material";
import { useLoginService } from "../hooks/api/Login";

export const ProtectedRoutes = ({
  children,
  permissions,
}: {
  children: ReactElement;
  permissions?: UserType[];
}) => {
  const { isSigned, getUserData, signOut } = useAuth();
  const { putLogout } = useLoginService();
  const navigate = useNavigate();
  if (!isSigned) return <Navigate to={ApplicationRoutes.SIGN_IN} />;
  if (permissions && !permissions?.includes(getUserData().type)) {
    return (
      <div>
        {" "}
        <p>Você não possui autorização para acessar esse recurso</p>{" "}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            putLogout().then(() => {
              signOut();
              navigate(ApplicationRoutes.SIGN_IN);
            });
          }}
        >
          Voltar para o Login
        </Button>
      </div>
    );
  }
  return <div>{children}</div>;
};
