import { JSX } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ApplicationRoutes } from "../utils/navigation/applicationRoutes";
import AgoraRTC, { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
import { VideoCall } from "../pages/videoCall";
import { PreVideoCall } from "../pages/preVideoCall";
import { UsersForm } from "../pages/createUser";
import { Doctors } from "../pages/campaignDoctor";
import { ListCampaignAppointments } from "../pages/listCampaignAppointments";
import { useAuth } from "../hooks/auth";
import { Login } from "../pages/login";
import { ProtectedRoutes } from "./protectedRoutes";
import FilePage from "../pages/file";
import { VideoCallEnd } from "../pages/videoCallEnd";
import { Layout } from "../components/layout";

export const AppRoutes = () => {
  const agoraEngine = useRTCClient(
    AgoraRTC.createClient({ codec: "vp8", mode: "rtc" })
  );

  const { isSigned } = useAuth();

  const privateRoutes: {
    route: string;
    element: JSX.Element;
  }[] = [
    {
      route: ApplicationRoutes.PRE_VIDEO_CALL,
      element: <PreVideoCall />,
    },
    {
      route: ApplicationRoutes.DOCTORS,
      element: <Doctors />,
    },
    { route: ApplicationRoutes.VIDEO_CALL_END, element: <VideoCallEnd /> },
  ];

  return (
    <Routes>
      <Route
        key={ApplicationRoutes.VIDEO_CALL}
        path={ApplicationRoutes.VIDEO_CALL}
        element={
          <AgoraRTCProvider client={agoraEngine}>
            <VideoCall />
          </AgoraRTCProvider>
        }
      />
      <Route
        key={ApplicationRoutes.CREATE_PATIENT}
        path={ApplicationRoutes.CREATE_PATIENT}
        element={<UsersForm />}
      />
      <Route
        key={ApplicationRoutes.FILE}
        path={ApplicationRoutes.FILE}
        element={<FilePage />}
      />

      <Route
        key={ApplicationRoutes.APPOINTMENT_LIST}
        path={ApplicationRoutes.APPOINTMENT_LIST}
        element={
          isSigned ? (
            <Layout>
              <ListCampaignAppointments />
            </Layout>
          ) : (
            <ListCampaignAppointments />
          )
        }
      />

      <Route
        key={ApplicationRoutes.CABIN_LIST}
        path={ApplicationRoutes.CABIN_LIST}
        element={
          isSigned ? (
            <Layout>
              <ListCampaignAppointments />
            </Layout>
          ) : (
            <ListCampaignAppointments />
          )
        }
      />

      <Route
        key={ApplicationRoutes.APPOINTMENT_LIST_PORTUGUESE}
        path={ApplicationRoutes.APPOINTMENT_LIST_PORTUGUESE}
        element={
          isSigned ? (
            <Layout>
              <ListCampaignAppointments />
            </Layout>
          ) : (
            <ListCampaignAppointments />
          )
        }
      />

      <Route
        path={ApplicationRoutes.SIGN_IN}
        element={
          isSigned ? (
            <Navigate to={ApplicationRoutes.APPOINTMENT_LIST} replace />
          ) : (
            <Login />
          )
        }
      />

      {privateRoutes.map(({ element, route }) => (
        <Route
          key={route}
          path={route}
          element={<ProtectedRoutes>{element}</ProtectedRoutes>}
        />
      ))}
    </Routes>
  );
};
