import { FC } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBarProps,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import logo from "../../assets/logo3x.png";

import { AppBarUiProps } from "./interfaces/AppBarUiProps";

export const AppBarUI: FC<AppBarUiProps> = ({
  userName,
  isOpenSettings,
  handleCloseSettings,
  handleOpenSettings,
  menuSettings,
}) => {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: "#f2f2f2",
  }));

  return (
    <AppBar>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={logo}
            alt={"Logotipo VivaSe"}
            style={{
              width: "8%",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#10A8BF",
                fontSize: "1.2rem",
                fontWeight: 600,
                marginRight: 1,
              }}
            >
              {userName}
            </Typography>
            <Tooltip title="Perfil e configuração">
              <IconButton
                id="button-profile"
                sx={{ p: 0, color: "#10A8BF", background: "#10A8BF" }}
                onClick={handleOpenSettings}
              >
                <Avatar sx={{ color: "#10A8BF", background: "#10A8BF" }}>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1.2rem",
                      fontWeight: 600,
                    }}
                  >
                    {userName[0]}
                  </Typography>
                </Avatar>
              </IconButton>
            </Tooltip>
          </div>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={isOpenSettings}
            onClose={handleCloseSettings}
          >
            {menuSettings.map(({ name, handleClick }) => (
              <MenuItem key={name} onClick={handleClick}>
                <Typography textAlign="center">{name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
