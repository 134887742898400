import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { useAppointmentService } from "../../hooks/api/Appointment";
import { AppointmentEntityResponse } from "../../hooks/api/Appointment/interfaces/appointment.response";
import { usePagination } from "../../hooks/pagination";
import { useDialog } from "../../hooks/dialog";
import { useAlert } from "../../hooks/alert";
import { ApplicationRoutes } from "../../utils/navigation/applicationRoutes";
import { format } from "../../utils/dateUtilts";
import { ListCampaignAppointmentsUI } from "./listCampaignAppointments.ui";
import { useAuth } from "../../hooks/auth";
import { UserInfoResponse } from "../../hooks/api/Login/interfaces/userInfo.response";
import {
  AttachFile,
  Delete,
  DeviceThermostat,
  Edit,
  Login,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { AppointmentFormInputs } from "./interfaces/AppointmentFormInputs";
import { appointmentEmpty } from "./utils/appointment.empty";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./validations/validation";
import { makeRequestBody } from "./utils/makeRequestBody";
import { morning, afternoon, night } from "./data/hoursValues";
import { PatientEntity } from "../../hooks/api/Patient/interfaces/user.response";
import { LoginRequestBody } from "../../hooks/api/Login/interfaces/login.request";
import { useLoginService } from "../../hooks/api/Login";
import { ServiceHoursEntity } from "../../hooks/api/ServiceHours/interfaces/serviceHours.response";
import { SpecialtyEntityResponse } from "../../hooks/api/Specialty/interfaces/specialtySearch.response";
import { useAvailableHoursService } from "../../hooks/api/ServiceHours";
import { useSpecialtyService } from "../../hooks/api/Specialty";

export const ListCampaignAppointmentsUIContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<UserInfoResponse>();
  const [visible, setVisible] = useState<boolean>(false);
  const [triageVisible, setTriageVisible] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [hours, setHours] = useState<ServiceHoursEntity[]>([]);
  const [filteredHours, setFilteredHours] = useState<ServiceHoursEntity[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [specialties, setSpecialties] = useState<SpecialtyEntityResponse[]>([]);
  const [specialty, setSpecialty] = useState<SpecialtyEntityResponse>();
  const { getHoursBySpecialty, updateStatus } = useAvailableHoursService();
  const { getAllByCampaign } = useSpecialtyService();

  const { getAppointmentsByCampaign, putUpdateAppointment } =
    useAppointmentService();
  const {
    data,
    page,
    limit,
    field,
    order,
    total,
    setField,
    setOrder,
    setData,
    setLimit,
    setPage,
    setTotal,
  } = usePagination<AppointmentEntityResponse>();
  const navigate = useNavigate();
  const { handleOpenDialog, handleCloseDialog } = useDialog();
  const { success, error } = useAlert();
  const { getUserData, isSigned, signIn } = useAuth();
  const { getLink, deleteAppointment } = useAppointmentService();
  const { postAutoLogin } = useLoginService();

  const handleClose = () => {
    setVisible(false);
  };

  const handleCloseTriage = () => {
    setTriageVisible(false);
  };

  const formMethods = useForm<AppointmentFormInputs>({
    defaultValues: appointmentEmpty,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    control,
    setValue,
    watch,
  } = formMethods;

  const handleOpen = ({
    appointment_date,
    cabin,
    hour,
    id,
  }: {
    id: number;
    cabin: string;
    appointment_date: string;
    hour: string;
  }) => {
    setSelectedId(id);
    setValue("cabin", cabin);
    setValue("date", appointment_date);

    setVisible(true);
  };

  const handleOpenTriage = (id: number) => {
    setSelectedId(id);
    setTriageVisible(true);
  };

  const handleFetchData = async () => {
    const user = await getUserData();
    setUser(user);
    setIsLoading(true);

    let cabin = undefined;
    let currentUrl = window.location.href;
    if (currentUrl.includes("consultas/cabine/")) {
      cabin = currentUrl.split("consultas/cabine/")[1];
      localStorage.setItem("cabin", cabin);
    }

    getAppointmentsByCampaign({ page, limit, field, order }, cabin)
      .then(({ data: newData, total }) => {
        setTotal(total);
        setData(newData);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTotal(0);
        setData([]);
        setIsLoading(false);
      });
  };

  const onSubmit = handleSubmit(async (data: AppointmentFormInputs) => {
    return updateStatus(data.hours.id)
      .then(async () => {
        if (!selectedId) return;
        const body = makeRequestBody(data);
        await putUpdateAppointment({ body }, selectedId)
          .then((res) => {
            handleFetchData();
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            error(err.response.data.message);
          });
      })
      .catch((err) => {
        console.log(err);
        error(err.response.data.message);
      });
  });

  const navigateToVideoCall = (id: number) => {
    return getLink(id)
      .then((response) => {
        const route = response.substring(response.lastIndexOf("/"));
        navigate("/introduction" + route);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigateToVideoCallPatient = (id: number, patient: PatientEntity) => {
    const body: { phone: string } = {
      phone: patient.user.email,
    };
    postAutoLogin({ body })
      .then((response) => {
        signIn(response.accessToken, response.user);
        return getLink(id)
          .then((response) => {
            const route = response.substring(response.lastIndexOf("/"));
            navigate("/introduction" + route);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePaginationStateChange = ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    if (page >= 0) setPage(page);
    if (pageSize >= 1) setLimit(pageSize);
  };

  const handlerSortModelChange = (newModel: GridSortModel) => {
    if (newModel?.length === 0) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    const { field, sort } = newModel[0];

    if (!field) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    setField(field as keyof AppointmentEntityResponse);
    setOrder(sort === "asc" ? "ASC" : "DESC");
  };

  const handleCancelAppointment = (id: number) => {
    handleOpenDialog("Atenção", "Deseja realmente cancelar a consulta?", [
      {
        label: "Não",
        action: handleCloseDialog,
      },
      {
        label: "Sim",
        isCancel: true,
        action: () => {
          deleteAppointment(id)
            .then(() => {
              handleCloseDialog();
              handleFetchData();
            })
            .catch((err) => {
              console.log(err);
              error("Houve um erro ao cancelar a consulta");
            });
        },
      },
    ]);
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, field, order]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const columns: GridColDef<AppointmentEntityResponse>[] = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "patient",
      headerName: "Paciente",
      width: 300,
      valueGetter: ({ row: { patient } }) => {
        return patient.user.name;
      },
    },
    {
      field: "doctor.doctor.specialties",
      headerName: "Especialidades",
      width: 300,
      valueGetter: ({ row: { doctor } }) => {
        return doctor.specialties
          .map((specialty: any) => specialty.name)
          .join(doctor.specialties.length > 1 ? ", " : "");
      },
    },
    {
      field: "doctor.user.name",
      headerName: "Médico(a)",
      width: 300,
      valueGetter: ({ row: { doctor } }) => {
        return doctor.user?.name;
      },
    },
    {
      field: "cabin",
      headerName: "Cabine",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: ({ row: { appointment_status, deletedAt } }) => {
        return deletedAt !== null
          ? "Cancelada"
          : appointment_status !== 3
          ? "Agendada"
          : "Finalizada";
      },
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      width: 250,
      getActions: ({
        row: {
          id,
          appointment_status,
          appointment_date,
          cabin,
          hours,
          deletedAt,
          patient,
        },
      }: GridRowParams<AppointmentEntityResponse>) =>
        isSigned
          ? user?.type === "Doctor"
            ? [
                <GridActionsCellItem
                  key={""}
                  icon={<Login />}
                  onClick={() => {
                    navigateToVideoCall(id);
                  }}
                  label="Para a video chamada"
                  disabled={appointment_status === 3 || deletedAt !== null}
                />,
              ]
            : [
                <GridActionsCellItem
                  key={""}
                  icon={<Edit />}
                  onClick={() => {
                    handleOpen({
                      appointment_date,
                      cabin,
                      hour: hours[0].hours,
                      id,
                    });
                  }}
                  label="Atualizar consulta"
                  disabled={appointment_status === 3 || deletedAt !== null}
                />,
                <GridActionsCellItem
                  key={""}
                  icon={<Delete />}
                  onClick={() => {
                    handleCancelAppointment(id);
                  }}
                  label="Cancelar consulta"
                  disabled={appointment_status === 3 || deletedAt !== null}
                />,
                <GridActionsCellItem
                  key={""}
                  icon={<DeviceThermostat />}
                  onClick={() => {
                    handleOpenTriage(id);
                  }}
                  label="Triagem"
                  disabled={appointment_status === 3 || deletedAt !== null}
                />,
                <GridActionsCellItem
                  key={""}
                  icon={<AttachFile />}
                  onClick={() => {
                    window.open("/print/" + id, "_blank");
                  }}
                  label="Imprimir receita"
                  disabled={deletedAt !== null}
                />,
              ]
          : [
              <GridActionsCellItem
                key={""}
                icon={<Login />}
                onClick={() => {
                  navigateToVideoCallPatient(id, patient);
                }}
                label="Para a video chamada"
                disabled={appointment_status === 3 || deletedAt !== null}
              />,
            ],
    },
  ];

  const navigateToForm = () => {
    navigate(ApplicationRoutes.CREATE_PATIENT);
  };

  useEffect(() => {
    getAllByCampaign().then((response) => {
      setSpecialties(response);
    });
  }, []);

  useEffect(() => {
    if (specialty) {
      getHoursBySpecialty(specialty.id).then((response) => {
        setHours(response);
        const dates = new Set(response.map((obj) => obj.date));
        const uniqueDatesArray: string[] = Array.from(dates);
        console.log(uniqueDatesArray);
        setDates(uniqueDatesArray);
      });
    }
  }, [specialty]);

  useEffect(() => {
    setFilteredHours(hours.filter((hour) => hour.date === watch("date")));
  }, [watch("date")]);

  useEffect(() => {
    const hours = watch("hours");
    if (hours.practice) {
      console.log(hours.practice.doctor.id);
      setValue("doctorId", hours.practice.doctor.id);
      setValue("cabin", hours.practice.name);
    }
  }, [watch("hours")]);

  return (
    <ListCampaignAppointmentsUI
      {...{
        rows: data,
        columns,
        isLoading,
        handlePaginationStateChange,
        handlerSortModelChange,
        paginationState: { page, pageSize: limit },
        totalRows: total,
        control,
        visible,
        handleClose,
        errors,
        register,
        isSubmitting,
        onSubmit,
        handleNavigateToForm: navigateToForm,
        dates,
        hours: filteredHours,
        setSpecialty,
        specialties,
        specialty,
        triageVisible,
        handleCloseTriage,
        selectedId,
        isSigned,
        handleFetchData
      }}
    />
  );
};
