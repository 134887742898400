import { useApi } from "..";
import { FileCreateRequestDto } from "./interfaces/fileCreate.request.dto";
import { FileResponseDto } from "./interfaces/fileResponse.dto";

export const useFileService = () => {
  const { post, get, del } = useApi();

  const postCreate = ({ body }: FileCreateRequestDto) => {
    return post("/file/upload", body, {
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return body;
      },
    });
  };

  const getFiles = async (id: number) => {
    return get<FileResponseDto[]>("/file/find-by-appointment/" + id)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  };

  const getFile = async (id: number) => {
    const { data } = await get<string>("/file/download/" + id);
    return data;
  };

  const deleteFile = async (id: number) => {
    return del("/file/delete/" + id);
  };

  return {
    postCreate,
    getFiles,
    getFile,
    deleteFile,
  };
};
