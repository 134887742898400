import { transformStringToNumberOrUndefined } from "../../../utils/stringUtils";
import yup from "../../../utils/yup";

export const schema = yup.object().shape({
  cabin: yup.string().required(),
  date: yup.string().required(),
  hours: yup
    .object()
    .shape({
      id: yup.number().required().min(1),
      date: yup.string().required(),
      startHour: yup.string().required(),
      endHour: yup.string().required(),
    })
    .required(),
  doctorId: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .required(),
});
