import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationRoutes } from "../../utils/navigation/applicationRoutes";
import { AppointmentEntityResponse } from "../../hooks/api/Appointment/interfaces/appointment.response";
import { useAppointmentService } from "../../hooks/api/Appointment";
import { PreVideoCall } from "./preVideoCall";
import { useAuth } from "../../hooks/auth";
import { useLoginService } from "../../hooks/api/Login";
import { LoginRequestBody } from "../../hooks/api/Login/interfaces/login.request";

export const PreVideoCallContainer = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState<number>(8);
  const [appointment, setAppointment] = useState<AppointmentEntityResponse>();
  const { id } = useParams();
  const { getAppointmentByHash } = useAppointmentService();
  const { postLogin } = useLoginService();
  const { isSigned, signIn, getToken } = useAuth();

  const checkLogin = () => {
    const token = getToken();
    console.log(isSigned, token);
    if (!isSigned || !token || token === "undefined") {
      const body: LoginRequestBody = {
        phone: "(20) 20202-2020",
        password: "t5dXEAgO2.$6",
      };
      postLogin({ body }).then((response) => {
        signIn(response.accessToken, response.user);
      });
    }
  };

  const findAppointment = async () => {
    if (id) {
      const result = await getAppointmentByHash(id);
      setAppointment(result);
    }
  };

  useEffect(() => {
    checkLogin();
    findAppointment();
  }, []);

  const directTo = async () => {
    if (appointment) {
      navigate(
        ApplicationRoutes.VIDEO_CALL.replace(":id", String(appointment.id))
      );
    }
  };

  const [banner, setBanner] = useState<
    {
      iconName: "callEnd" | "mic" | "videoCall" | "message" | "file";
      text: string;
    }[]
  >([
    {
      iconName: "videoCall",
      text: "Toque sobre o botão da câmera para habilitar e desabilitar a visualização durante a consulta.",
    },
    {
      iconName: "mic",
      text: "Utilize o botão de microfone para habilitar e desabilitar sua voz.",
    },
    {
      iconName: "callEnd",
      text: "No fim da consulta toque nesse botão para encerrar a ligação.",
    },
    {
      iconName: "message",
      text: "Para digitar algo para o médico utilize o campo de texto.",
    },
    {
      iconName: "file",
      text: "Caso necessite enviar um exame ou receita para o médico, utilize o campo arquivo.",
    },
  ]);
  const [chosenBanner, setChosenBanner] = useState<{
    iconName: "callEnd" | "mic" | "videoCall" | "message" | "file";
    text: string;
  }>(banner[0]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);

      // Check if the current time is a multiple of 15 seconds
      if (seconds <= 1) {
        directTo();
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [seconds]);
  return (
    <Container maxWidth="sm">
      <PreVideoCall
        {...{ seconds, banners: banner, chosenBanner, setChosenBanner }}
      />
    </Container>
  );
};
