import { AppointmentCreateRequestBody } from "../../../hooks/api/Appointment/interfaces/appointment.creatre.request";
import { convertToNumberOrUndefined } from "../../../utils/stringUtils";
import { AppointmentFormInputs } from "../interfaces/AppointmentFormInputs";

export const makeRequestBody = (
  obj: AppointmentFormInputs
): AppointmentCreateRequestBody => {
  return {
    cabin: obj.cabin,
    date: obj.date,
    hours: obj.hours ? obj.hours.startHour : "",
    doctorId: obj.doctorId,
    type: 0,
  };
};
