import { useEffect, useState } from "react";
import { DoctorUI } from "./doctors.ui";
import { useAlert } from "../../hooks/alert";
import { useAppointmentService } from "../../hooks/api/Appointment";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppointmentFormInputs } from "./interfaces/AppointmentFormInputs";
import { appointmentEmpty } from "./utils/appointment.empty";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./validations/validation";
import { makeRequestBody } from "./utils/makeRequestBody";
import { ApplicationRoutes } from "../../utils/navigation/applicationRoutes";
import { useAvailableHoursService } from "../../hooks/api/ServiceHours";
import { ServiceHoursEntity } from "../../hooks/api/ServiceHours/interfaces/serviceHours.response";
import { useSpecialtyService } from "../../hooks/api/Specialty";
import { SpecialtyEntityResponse } from "../../hooks/api/Specialty/interfaces/specialtySearch.response";
import { useAuth } from "../../hooks/auth";

export const DoctorContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [hours, setHours] = useState<ServiceHoursEntity[]>([]);
  const [filteredHours, setFilteredHours] = useState<ServiceHoursEntity[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [specialties, setSpecialties] = useState<SpecialtyEntityResponse[]>([]);
  const [specialty, setSpecialty] = useState<SpecialtyEntityResponse>();
  const { getHoursBySpecialty, updateStatus } = useAvailableHoursService();
  const { getAllByCampaign } = useSpecialtyService();
  //const { id } = useParams();
  const navigate = useNavigate();
  const { postCreate, getLastLink } = useAppointmentService();
  const { signOut } = useAuth();
  const { success, error } = useAlert();

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const formMethods = useForm<AppointmentFormInputs>({
    defaultValues: appointmentEmpty,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    control,
    watch,
    setValue,
  } = formMethods;

  const onSubmit = handleSubmit(async (data: AppointmentFormInputs) => {
    const body = makeRequestBody(data);
    return updateStatus(data.hours.id)
      .then(() => {
        return postCreate({ body })
          .then((res) => {
            success("Consulta criada com sucesso!");
            signOut();
            navigate(ApplicationRoutes.CREATE_PATIENT);
          })
          .catch((err) => {
            console.log(err);
            error(err.response.data.message);
          });
      })
      .catch((err) => {
        console.log(err);
        error(err.response.data.message);
      });
  });

  useEffect(() => {
    getAllByCampaign().then((response) => {
      setSpecialties(response);
    });
  }, []);

  useEffect(() => {
    if (specialty) {
      getHoursBySpecialty(specialty.id).then((response) => {
        setHours(response);
        const dates = new Set(response.map((obj) => obj.date));
        const uniqueDatesArray: string[] = Array.from(dates);
        console.log(uniqueDatesArray);
        setDates(uniqueDatesArray);
      });
    }
  }, [specialty]);

  useEffect(() => {
    setFilteredHours(hours.filter((hour) => hour.date === watch("date")));
  }, [watch("date")]);

  useEffect(() => {
    const hours = watch("hours");
    if (hours.practice) {
      console.log(hours.practice.doctor.id);
      setValue("doctorId", hours.practice.doctor.id);
      setValue("cabin", hours.practice.name);
    }
  }, [watch("hours")]);

  return (
    <DoctorUI
      {...{
        onSubmit,
        register,
        errors,
        isLoading,
        control,
        specialty,
        setSpecialty,
        specialties,
        hours: filteredHours,
        dates,
      }}
    />
  );
};
