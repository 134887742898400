import { Button, Typography } from "@mui/material";

export const VideoCallEndUI = ({
  handleGoToFile,
}: {
  handleGoToFile: () => void;
}) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ color: "#000", fontFamily: "Arial, Helvetica" }}
          variant="h3"
          component="h1"
          color="secondary"
        >
          Chamada Finalizada
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoToFile}>
          Imprimir Receita
        </Button>
      </div>
    </>
  );
};
