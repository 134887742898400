import axios, { AxiosError } from "axios";
import { useAuth } from "../auth";
import { useAlert } from "../alert";
import { LoginResponse } from "./Login/interfaces/login.response";

export const useApi = () => {
  const { getToken, signIn, signOut } = useAuth();
  const { error: errorAlert, warning } = useAlert();

  const baseUrl = process.env.REACT_APP_API_URL;

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  api.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {}
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return new Promise((resolve, reject) => {
        const originalReq = err.config;
        console.log(err);
        if (
          err?.response?.status == 401 &&
          err?.config &&
          !err?.config?._retry
        ) {
          originalReq._retry = true;
          const token = getToken();
          if (token) {
            let res = api
              .put<LoginResponse>(`${baseUrl}/token/refresh`, {
                oldToken: token,
              })
              .then((res) => {
                signIn(res?.data.accessToken, res?.data.user);
                originalReq.headers[
                  "Authorization"
                ] = `Bearer ${res?.data?.accessToken}`;
                return axios(originalReq);
              })
              .catch((error) => {
                console.log("Refresh err:", error);
                errorAlert(
                  "Houve um erro ao atualizar o token, retornando ao login!"
                );
                signOut();
              });
            resolve(res);
          } else {
            reject(err);
          }
        } else {
          reject(err);
        }
      });
    }
  );

  const { get, delete: del, post, put, patch } = api;

  return {
    get,
    del,
    post,
    put,
    patch,
  };
};
