import { JSX } from "react";

import { Grid } from "@mui/material";
import { AppBarUiProps } from "../appBar/interfaces/AppBarUiProps";
import { AppBar } from "../appBar";

export const LayoutUI = ({
  children,
  appBarProps,
}: {
  children: JSX.Element;
  appBarProps: AppBarUiProps;
}) => {
  return (
    <Grid
      container
      flexDirection={"row"}
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        margin: 0,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          height: "56px",
        }}
      >
        <AppBar {...appBarProps} />
      </Grid>
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          padding: "15px",
          backgroundColor: "#ffff",
          overflow: "auto",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
