import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { VideoCallEndUI } from "./videoCallEnd";

export const VideoCallEndContainer = () => {
  const { id } = useParams();

  const handleGoToFile = () => {
    window.open("/print/" + id, "_blank");
  };

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <VideoCallEndUI {...{ handleGoToFile }} />
      </div>
    </>
  );
};
