import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { AppointmentFormInputs } from "./interfaces/AppointmentFormInputs";
import { Dispatch, SetStateAction } from "react";
import { useInputsStyle } from "../../hooks/inputsStyle";
import { LoadingButton } from "../../components/loadingButton";
import { Dropdown } from "../../components/select";
import { SpecialtyEntityResponse } from "../../hooks/api/Specialty/interfaces/specialtySearch.response";
import { ServiceHoursEntity } from "../../hooks/api/ServiceHours/interfaces/serviceHours.response";
import { HoursRadioGroup } from "./components/hoursRadioGroup";
import dayjs from "dayjs";

export const DoctorUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
  control,
  specialty,
  setSpecialty,
  specialties,
  hours,
  dates,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<AppointmentFormInputs>;
  errors: FieldErrors<AppointmentFormInputs>;
  isLoading: boolean;
  control: Control<AppointmentFormInputs>;
  specialty: SpecialtyEntityResponse | undefined;
  setSpecialty: Dispatch<SetStateAction<SpecialtyEntityResponse | undefined>>;
  specialties: SpecialtyEntityResponse[];
  hours: ServiceHoursEntity[];
  dates: string[];
}) => {
  const theme = useTheme();
  const { container, inputsSections, normalInput, buttonSection, buttonSize } =
    useInputsStyle({ theme });
  return (
    <Grid {...container}>
      <Grid item xs={12}>
        <Grid item xs={12} mb={3}>
          <Typography variant="h4" component="h1" color="secondary">
            Criar Usuário
          </Typography>
        </Grid>
        <form onSubmit={onSubmit}>
          <Grid {...inputsSections}>
            <Grid {...normalInput}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Especialidade
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="input-specialty"
                  value={specialty?.id}
                  label="Especialidade"
                  onChange={(e) => {
                    setSpecialty(
                      specialties.find((item) => item.id === e.target.value)
                    );
                  }}
                >
                  {specialties.map((specialty) => (
                    <MenuItem
                      id={`input-specialty-${specialty.id}`}
                      key={specialty.id}
                      value={specialty.id}
                    >
                      {specialty.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid {...normalInput}>
              <Dropdown
                id="dropdown-type"
                control={control}
                label="Data"
                name="date"
                disabled={isLoading}
                key="gender"
                validationMessage={errors.date?.message}
                options={dates.map((date) => {
                  return {
                    label: dayjs(date).format("YYYY-MM-DD"),
                    value: date,
                  };
                })}
              />
            </Grid>
            <Grid {...normalInput}>
              <HoursRadioGroup {...{ control, hours, isLoading }} />
            </Grid>
          </Grid>
          <Grid {...buttonSection}>
            <Grid {...buttonSize}>
              <LoadingButton
                id="button-submit"
                label={"Cadastrar"}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
