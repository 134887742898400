import { useApi } from "..";
import { ServiceHoursEntity } from "./interfaces/serviceHours.response";

export const useAvailableHoursService = () => {
  const { get, patch } = useApi();

  const getHoursBySpecialty = (specialtyId: number) => {
    return get<ServiceHoursEntity[]>(
      "/service-hour/find-available/" + specialtyId
    ).then(({ data }) => {
      return data;
    });
  };

  const updateStatus = (id: number) => {
    return patch("/service-hour/update-status/" + id);
  };

  return {
    getHoursBySpecialty,
    updateStatus,
  };
};
