import {
  CallEnd,
  InsertDriveFileOutlined,
  MessageOutlined,
  Mic,
  VideocamOutlined,
} from "@mui/icons-material";
import { Container, Box } from "@mui/material";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../hooks/windowSize";

export const Display = ({
  banner,
  seconds,
}: {
  banner: {
    iconName: "callEnd" | "mic" | "videoCall" | "message" | "file";
    text: string;
  };
  seconds: number;
}) => {
  const { height, width } = useWindowDimensions();
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {banner.iconName === "callEnd" ? (
        <CallEnd sx={{ fontSize: 60, color: "#CC0000" }} />
      ) : banner.iconName === "mic" ? (
        <Mic sx={{ fontSize: 60, color: "#0A6172" }} />
      ) : banner.iconName === "videoCall" ? (
        <VideocamOutlined sx={{ fontSize: 60, color: "#0A6172" }} />
      ) : banner.iconName === "message" ? (
        <MessageOutlined sx={{ fontSize: 60, color: "#0A6172" }} />
      ) : (
        <InsertDriveFileOutlined sx={{ fontSize: 60, color: "#0A6172" }} />
      )}
      <h2 style={{ textAlign: "center", fontFamily: "sans-serif" }}>
        {banner.text}
      </h2>
      <h2 style={{ textAlign: "center", fontFamily: "sans-serif" }}>
        {seconds}
      </h2>
    </Box>
  );
};
