import { FC } from "react";

import { Button, CircularProgress } from "@mui/material";
import { LoadingButtonProps } from "./interfaces/LoadingButtonProps";

export const LoadingButton: FC<LoadingButtonProps> = ({
  id,
  type = "submit",
  label,
  isLoading = false,
  disabled = false,
}) => {
  return (
    <Button
      id={id}
      fullWidth
      variant="contained"
      color="primary"
      type={type}
      disabled={disabled || isLoading}
    >
      {isLoading ? <CircularProgress color="secondary" /> : label}
    </Button>
  );
};
