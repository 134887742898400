import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { LoginFormInputs } from "./interfaces/LoginFormInputs";
import { LoginUI } from "./login.ui";
import { loginEmpty } from "./utils/login.empty";
import { makeRequestBody } from "./utils/makeRequestBody";
import { schema } from "./validations/validation";
import { useLoginService } from "../../hooks/api/Login";
import { useAuth } from "../../hooks/auth";
import { useAlert } from "../../hooks/alert";
import { ApplicationRoutes } from "../../utils/navigation/applicationRoutes";

export const LoginContainer = () => {
  const { postLogin } = useLoginService();
  const { signIn } = useAuth();
  const { error } = useAlert();
  const navigate = useNavigate();

  const formMethods = useForm<LoginFormInputs>({
    defaultValues: loginEmpty,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
  } = formMethods;

  const onSubmit = handleSubmit(async (data: LoginFormInputs) => {
    const body = makeRequestBody(data);
    await postLogin({ body })
      .then((res) => {
        signIn(res.accessToken, res.user);
      })
      .catch((err) => error("Usuário ou senha inválidos"));
  });

  return <LoginUI {...{ onSubmit, register, isLoading, errors }} />;
};
