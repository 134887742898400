import { FC, PropsWithChildren, useMemo, useState } from "react";

import { Alert, AlertColor, Snackbar } from "@mui/material";
import { AlertContextProps } from "../../context/alert/interfaces/AlertContextProps";
import { AlertContext } from "../../context/alert";

export const AlertProvider: FC<{ children: any }> = ({
  children,
}: {
  children: PropsWithChildren<{}>;
}) => {
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor>("success");

  const handleOpenAlert = (message: string, severity: AlertColor) => {
    setMessage(message);
    setIsOpen(true);
    setSeverity(severity);
  };

  const handleCloseAlert = () => {
    setIsOpen(false);
    setMessage("");
  };

  const contextProps: AlertContextProps = useMemo(
    () => ({
      handleCloseAlert,
      error: (message: string) => handleOpenAlert(message, "error"),
      success: (message: string) => handleOpenAlert(message, "success"),
      warning: (message: string) => handleOpenAlert(message, "warning"),
    }),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [message, isOpen, severity]
  );

  return (
    <AlertContext.Provider value={contextProps}>
      <>{children}</>
      <Snackbar
        open={isOpen}
        autoHideDuration={4500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};
