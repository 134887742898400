import { useMemo, useState } from "react";
import AuthContext from "../../context/auth";
import { UserType } from "../../hooks/api/Login/interfaces/userInfo.response";

type UserInfoResponse = {
  id: number;
  name: string;
  type: UserType;
  email: string;
};

interface ProviderProps {
  children: any;
}

export const AuthProvider: React.FC<ProviderProps> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const tokenKey = "@token";
  const nameUserKey = "@nameUser";

  const [isSigned, setIsSigned] = useState<boolean>(
    !!localStorage.getItem(tokenKey) && !!localStorage.getItem(nameUserKey)
  );

  const signIn = (token: string, user: UserInfoResponse) => {
    localStorage.setItem(tokenKey, token);
    localStorage.setItem(nameUserKey, JSON.stringify(user));
    setIsSigned(true);
  };

  const signOut = () => {
    localStorage.clear();
    setIsSigned(false);
  };

  const getToken = () => {
    const token = localStorage.getItem(tokenKey);
    return token;
  };

  const getUserData = (): UserInfoResponse => {
    const user = localStorage.getItem(nameUserKey);
    if (user) {
      return JSON.parse(user ?? "{}");
    } else {
      return {} as UserInfoResponse;
    }
  };

  const contextValues = useMemo(
    () => ({
      isSigned,
      signIn,
      signOut,
      getToken,
      getUserData,
    }),
    [isSigned]
  );

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};
