import { Container, Box, IconButton, Grid, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Display } from "./components/display";
import {
  CallEnd,
  InsertDriveFileOutlined,
  MessageOutlined,
  Mic,
  VideocamOutlined,
} from "@mui/icons-material";
import useWindowDimensions from "../../hooks/windowSize";
import useDeviceDetector from "../../hooks/deviceDetector";

export const PreVideoCall = ({
  seconds,
  banners,
  chosenBanner,
  setChosenBanner,
}: {
  seconds: number;
  banners: {
    iconName: "callEnd" | "mic" | "videoCall" | "message" | "file";
    text: string;
  }[];
  chosenBanner: {
    iconName: "callEnd" | "mic" | "videoCall" | "message" | "file";
    text: string;
  };
  setChosenBanner: Dispatch<
    SetStateAction<{
      iconName: "callEnd" | "mic" | "videoCall" | "message" | "file";
      text: string;
    }>
  >;
}) => {
  const { height, width } = useWindowDimensions();
  const device = useDeviceDetector();
  const theme = useTheme();
  return (
    <Container sx={{ marginTop: 20 }}>
      {chosenBanner && <Display banner={chosenBanner} seconds={seconds} />}
      <div
        style={{
          width: device === "desktop" ? "56%" : "90%",
          position: "absolute",
          bottom: 60,
          left: device === "desktop" ? "22%" : "5%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <div>
          <IconButton
            onClick={() => setChosenBanner(banners[0])}
            sx={{ bgcolor: "#0A6172" }}
          >
            <VideocamOutlined sx={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div>
          <IconButton
            onClick={() => setChosenBanner(banners[1])}
            sx={{ bgcolor: "#0A6172" }}
          >
            <Mic sx={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div>
          <IconButton
            onClick={() => setChosenBanner(banners[2])}
            sx={{ bgcolor: "#CC0000" }}
          >
            <CallEnd sx={{ color: "#fff", fontSize: 30 }} />
          </IconButton>
        </div>
        <div>
          <IconButton
            onClick={() => setChosenBanner(banners[3])}
            sx={{ bgcolor: "#0A6172" }}
          >
            <MessageOutlined sx={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div>
          <IconButton
            onClick={() => setChosenBanner(banners[4])}
            sx={{ bgcolor: "#0A6172" }}
          >
            <InsertDriveFileOutlined sx={{ color: "#fff" }} />
          </IconButton>
        </div>
      </div>
    </Container>
  );
};
