import { AppointmentUpdateTriageRequestBody } from "../../../hooks/api/Appointment/interfaces/appointment.triage.update.request";
import { AppointmentUpdateRequestBody } from "../../../hooks/api/Appointment/interfaces/appointment.update.request";
import { AppointmentTriageFormInputs } from "../interfaces/AppointmentTriageFormInputs";

export const makeRequestTriageBody = (
  obj: AppointmentTriageFormInputs
): AppointmentUpdateTriageRequestBody => {
  return {
    height: obj.height,
    pressure: obj.pressure,
    temperature: obj.temperature,
    weight: obj.weight,
  };
};
