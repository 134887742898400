import {
  Control,
  FieldErrors,
  UseFormRegister,
  useForm,
} from "react-hook-form";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppointmentFormInputs } from "../interfaces/AppointmentFormInputs";
import { LoadingButton } from "../../../components/loadingButton";
import { SpecialtyEntityResponse } from "../../../hooks/api/Specialty/interfaces/specialtySearch.response";
import { ServiceHoursEntity } from "../../../hooks/api/ServiceHours/interfaces/serviceHours.response";
import { Dropdown } from "../../../components/select";
import dayjs from "dayjs";
import { HoursRadioGroup } from "./hoursRadioGroup";
import { AppointmentTriageFormInputs } from "../interfaces/AppointmentTriageFormInputs";
import { appointmentTriageEmpty } from "../utils/appointment.triage.empty";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../validations/triageValidation";
import { useAppointmentService } from "../../../hooks/api/Appointment";
import { makeRequestTriageBody } from "../utils/makeTriageRequestBody";
import { useAlert } from "../../../hooks/alert";
import { InputText } from "../../../components/input";
import { NumberInput } from "../../../components/numberInput";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export const TriageModal = ({
  selectedId,
  visible,
  handleClose,
}: {
  selectedId: number;
  visible: boolean;
  handleClose: () => void;
}) => {
  const { putTriageData, getAppointment } = useAppointmentService();
  const { error } = useAlert();

  const formMethods = useForm<AppointmentTriageFormInputs>({
    defaultValues: appointmentTriageEmpty,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    control,
    setValue,
    reset,
  } = formMethods;

  useEffect(() => {
    reset();
    if (visible) {
      getAppointment(selectedId).then((response) => {
        if (response.pressure) setValue("pressure", response.pressure);
        if (response.temperature) setValue("temperature", response.temperature);
        if (response.weight) setValue("weight", response.weight);
        if (response.height) setValue("height", response.height);
      });
    }
  }, [visible]);

  const onSubmit = handleSubmit(async (data: AppointmentTriageFormInputs) => {
    const body = makeRequestTriageBody(data);
    return putTriageData(selectedId, { body })
      .then(async () => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        error(err.response.data.message);
      });
  });

  const theme = useTheme();

  return (
    <Modal open={visible} onClose={handleClose}>
      <Box sx={style}>
        <form onSubmit={onSubmit}>
          <div style={{ marginBottom: 15 }}>
            <InputText
              id="input-pressure"
              label="Pressão"
              placeholder="10/8"
              validationMessage={errors.pressure?.message}
              register={register("pressure")}
              disabled={isSubmitting}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <NumberInput
              id="input-temperature"
              label="Temperatura"
              validationMessage={errors.temperature?.message}
              name={"temperature"}
              disabled={isSubmitting}
              suffix="°C"
              control={control}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <NumberInput
              id="input-weight"
              label="Peso"
              validationMessage={errors.weight?.message}
              name="weight"
              disabled={isSubmitting}
              suffix="Kg"
              control={control}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <NumberInput
              id="input-height"
              label="Altura"
              validationMessage={errors.height?.message}
              name="height"
              disabled={isSubmitting}
              suffix="m"
              control={control}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              id="button-submit"
              label="Atualizar"
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </Box>
    </Modal>
  );
};
