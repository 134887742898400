import { TextField } from "@mui/material";
import { Controller, FieldValues } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { InputTextProps } from "./interfaces/InputTextProps";

export const NumberInput = <TFieldValues extends FieldValues>({
  id,
  name,
  label,
  disabled = false,
  placeholder,
  validationMessage = "",
  control,
  prefix,
  suffix,
}: InputTextProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <NumericFormat
          id={id}
          valueIsNumericString={true}
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          label={label}
          error={!!validationMessage}
          helperText={validationMessage}
          autoComplete="off"
          disabled={disabled || control?._formState.isSubmitting}
          placeholder={placeholder}
          type="text"
          prefix={prefix}
          suffix={suffix}
          value={value}
          onValueChange={(values) => onChange(values.value)}
        />
      )}
    />
  );
};
