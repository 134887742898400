import { useApi } from "..";
import { PaginatedRequest } from "../interfaces/paginated.request";
import { PaginatedResponse } from "../interfaces/paginated.response";
import { AppointmentCreateRequest } from "./interfaces/appointment.creatre.request";
import { AppointmentEntityResponse } from "./interfaces/appointment.response";
import { AppointmentUpdateTriageRequest } from "./interfaces/appointment.triage.update.request";
import { AppointmentUpdateRequest } from "./interfaces/appointment.update.request";
import { MessageDtoResponse } from "./interfaces/appointmentMessage.response";

export const useAppointmentService = () => {
  const { post, put, get, del } = useApi();

  const postCreate = ({ body }: AppointmentCreateRequest) => {
    return post("/appointment/create-campaign", body);
  };

  const getAppointment = async (id: number) => {
    const { data } = await get<AppointmentEntityResponse>(
      "/appointment/find-one/" + id
    );
    return data;
  };

  const getAppointmentByHash = async (hash: string) => {
    const { data } = await get<AppointmentEntityResponse>(
      "/appointment/find-one-hash/" + hash
    );
    return data;
  };

  const getAppointmentsByCampaign = async (
    params: PaginatedRequest<AppointmentEntityResponse>,
    cabin?: string
  ): Promise<PaginatedResponse<AppointmentEntityResponse>> => {
    return get<PaginatedResponse<AppointmentEntityResponse>>(
      "/appointment/find-campaign/",
      { params: { ...params, cabin } }
    ).then((response) => {
      return response.data;
    });
  };

  const getLastLink = async () => {
    const { data } = await get<string>("/appointment/find-last-link/");
    return data;
  };

  const getLink = async (id: number) => {
    const { data } = await get<string>("/appointment/find-link/" + id);
    return data;
  };

  const getAppointmentMessages = async (id: number) => {
    const { data } = await get<MessageDtoResponse[]>(
      `/appointment/${id}/messages`
    );
    return data;
  };

  const createAppointmentMessages = async (
    appointmentId: number,
    message: string
  ) => {
    const { data } = await post<any>(`/appointment/${appointmentId}/messages`, {
      message,
    });
    return data;
  };

  const putFinishAppointment = async (id: number) => {
    return put("/appointment/finish/" + id);
  };

  const putTriageData = async (
    id: number,
    { body }: AppointmentUpdateTriageRequest
  ) => {
    return put("/appointment/triage/" + id, body);
  };

  const putUpdateAppointment = async (
    { body }: AppointmentUpdateRequest,
    id: number
  ) => {
    return put("/appointment/update-campaign/" + id, body);
  };

  const deleteAppointment = async (id: number): Promise<void> => {
    return del(`/appointment/delete/${id}`);
  };

  return {
    postCreate,
    getAppointment,
    getAppointmentByHash,
    getAppointmentMessages,
    getLastLink,
    createAppointmentMessages,
    getAppointmentsByCampaign,
    getLink,
    putFinishAppointment,
    putUpdateAppointment,
    putTriageData,
    deleteAppointment,
  };
};
