import { AppointmentUpdateRequestBody } from "../../../hooks/api/Appointment/interfaces/appointment.update.request";
import { AppointmentFormInputs } from "../interfaces/AppointmentFormInputs";

export const makeRequestBody = (
  obj: AppointmentFormInputs
): AppointmentUpdateRequestBody => {
  return {
    cabin: obj.cabin,
    date: obj.date,
    hours: obj.hours ? obj.hours.startHour : "",
    doctorId: obj.doctorId,
  };
};
