import { UserFormInputs } from "../interfaces/UserFormInputs";

export const userEmpty: UserFormInputs = {
  name: "",
  phone: "",
  email: "",
  // password: "",
  // confirmPassword: "",
  birthdate: "",
  city: "",
  gender: "male",
  labor: "",
  region: "centerwest",
  state: "",
  susNumber: "",
  income: 0,
};
