import { Theme } from '@mui/material';

export const useInputsStyle = ({ theme: { spacing } }: { theme: Theme }) => {
  const inputStyle = (mdValue?: number, lgValue?: number) => {
    const md = mdValue ? mdValue : 6;
    const lg = lgValue ? lgValue : 4;

    return {
      item: true,
      xs: 12,
      md: md,
      lg: lg,
      padding: {
        xs: spacing(0, 0.5, 2, 0.5),
        md: spacing(0, 1.2, 2, 1.2),
      },
    };
  };

  const container = {
    container: true,
    sx: { padding: spacing(0.5), flexDirection: 'row' },
  };

  const inputsSections = {
    item: true,
    xs: 12,
    sx: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'rows',
      alignItems: 'start',
      paddingTop: '0px !important',
      marginBottom: {
        md: 2,
      },
    },
  };

  const buttonSection = {
    container: true,
    sx: { flexDirection: 'row', justifyContent: 'flex-end' },
  };

  const normalInput = inputStyle();
  const smallInput = inputStyle(3.5, 2);
  const buttonSize = inputStyle(6, 2);

  return {
    container,
    inputsSections,
    normalInput,
    buttonSection,
    buttonSize,
    smallInput,
  };
};
