import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import { AppointmentFormInputs } from "../interfaces/AppointmentFormInputs";
import { ServiceHoursEntity } from "../../../hooks/api/ServiceHours/interfaces/serviceHours.response";

export const HoursRadioGroup = ({
  control,
  hours,
  isLoading,
}: {
  control: Control<AppointmentFormInputs>;
  hours: ServiceHoursEntity[];
  isLoading: boolean;
}) => {
  return (
    <Controller
      name={"hours"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <FormLabel>{"Hora"}</FormLabel>
          <RadioGroup>
            {hours.map((option) => (
              <FormControlLabel
                disabled={isLoading}
                style={{
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onChange={() => {
                  onChange(option);
                }}
                control={<Radio />}
                checked={value.id === option.id}
                label={`${option.startHour} - ${option.endHour}`}
              />
            ))}
          </RadioGroup>
        </>
      )}
    />
  );
};
