import { Grid, Link, Typography, useTheme } from "@mui/material";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { LoginFormInputs } from "./interfaces/LoginFormInputs";
import { InputText } from "../../components/input";
import { LoadingButton } from "../../components/loadingButton";
import logo from "../../assets/logo3x.png";

export const LoginUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<LoginFormInputs>;
  errors: FieldErrors<LoginFormInputs>;
  isLoading: boolean;
}) => {
  const {
    palette: { primary },
    spacing,
  } = useTheme();

  return (
    <Grid
      container
      sx={{ height: "97vh" }}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        xs={11}
        md={10}
        lg={5}
        xl={4}
        sx={{
          border: `1px solid ${primary.main}`,
          borderRadius: spacing(1),
          padding: spacing(2),
        }}
      >
        <Grid item xs={12}>
          <img
            src={logo}
            alt={"Logotipo VivaSe"}
            style={{
              width: "50%",
              marginLeft: "20%",
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        </Grid>
        <form onSubmit={onSubmit}>
          <Grid container item xs={12} justifyContent="right">
            <Grid item mb={3} xs={12}>
              <InputText
                id="input-email"
                label="Email"
                validationMessage={errors.username?.message}
                register={register("username")}
                disabled={isLoading}
              />
            </Grid>
            <Grid item mb={3} xs={12}>
              <InputText
                id="input-password"
                label="Senha"
                type="password"
                validationMessage={errors.password?.message}
                register={register("password")}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <LoadingButton
                id="button-submit"
                label="Entrar"
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
