import { Button, Grid, Typography } from "@mui/material";
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";

import PlusIcon from "@mui/icons-material/Add";
import { PaginatedTable } from "../../components/paginatedTable";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { AppointmentFormInputs } from "./interfaces/AppointmentFormInputs";
import { Dispatch, SetStateAction } from "react";
import { AppointmentModal } from "./components/appointmentModal";
import { SpecialtyEntityResponse } from "../../hooks/api/Specialty/interfaces/specialtySearch.response";
import { ServiceHoursEntity } from "../../hooks/api/ServiceHours/interfaces/serviceHours.response";
import { TriageModal } from "./components/triageModal";

export const ListCampaignAppointmentsUI = ({
  columns,
  rows,
  handlePaginationStateChange,
  handlerSortModelChange,
  paginationState,
  totalRows,
  isLoading,
  control,
  visible,
  handleClose,
  errors,
  register,
  isSubmitting,
  onSubmit,
  handleNavigateToForm,
  dates,
  hours,
  setSpecialty,
  specialties,
  specialty,
  triageVisible,
  handleCloseTriage,
  selectedId,
  isSigned,
  handleFetchData
}: {
  columns: GridColDef[];
  rows: GridRowsProp;
  handlePaginationStateChange: ({
    page,
    pageSize,
  }: GridPaginationModel) => void;
  handlerSortModelChange: (newModel: GridSortModel) => void;
  paginationState: { page: number; pageSize: number };
  totalRows: number;
  isLoading: boolean;
  control: Control<AppointmentFormInputs>;
  visible: boolean;
  triageVisible: boolean;
  handleClose: () => void;
  handleCloseTriage: () => void;
  register: UseFormRegister<AppointmentFormInputs>;
  errors: FieldErrors<AppointmentFormInputs>;
  isSubmitting: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  handleNavigateToForm: (id?: string) => void;
  specialty: SpecialtyEntityResponse | undefined;
  setSpecialty: Dispatch<SetStateAction<SpecialtyEntityResponse | undefined>>;
  specialties: SpecialtyEntityResponse[];
  hours: ServiceHoursEntity[];
  dates: string[];
  selectedId: number | undefined;
  isSigned: boolean;
  handleFetchData: () => void;
}) => {
  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <Grid container justifyContent="space-between">
          <Grid item xs={4}>
            <Typography
              variant="h4"
              component={"h1"}
              color="primary"
              fontWeight="bold"
            >
              Consultas
            </Typography>
          </Grid>
          {isSigned && (
            <div style={{display: "flex", flexDirection: "row"}}>
              <Grid item>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  fontWeight: "bold",
                  color: "white",
                }}
                onClick={() => handleFetchData()}
              >
                Atualizar lista
              </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  onClick={() => handleNavigateToForm()}
                >
                  Adicionar consulta
                </Button>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PaginatedTable
          {...{
            columns,
            rows,
            handlePaginationStateChange,
            handlerSortModelChange,
            paginationState,
            totalRows,
            isLoading,
          }}
        />
      </Grid>
      <AppointmentModal
        {...{
          control,
          visible,
          handleClose,
          errors,
          register,
          isLoading: isSubmitting,
          onSubmit,
          dates,
          hours,
          setSpecialty,
          specialties,
          specialty,
        }}
      />

      {selectedId && (
        <TriageModal
          {...{
            selectedId,
            handleClose: handleCloseTriage,
            visible: triageVisible,
          }}
        />
      )}
    </Grid>
  );
};
