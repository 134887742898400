export enum ApplicationRoutes {
  PRE_VIDEO_CALL = "/introduction/:id",
  VIDEO_CALL = "/video/:id",
  CREATE_PATIENT = "/create-patient",
  DOCTORS = "/doctors",
  APPOINTMENT_LIST = "/appointments",
  APPOINTMENT_LIST_PORTUGUESE = "/consultas",
  CABIN_LIST = "/consultas/cabine/:cabin",
  SIGN_IN = "/",
  FILE = "/print/:id",
  VIDEO_CALL_END = "call-end/:id",
}
