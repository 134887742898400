import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../../hooks/windowSize";
import { FileResponseDto } from "../../../../hooks/api/files/interfaces/fileResponse.dto";
import { useAuth } from "../../../../hooks/auth";
import useDeviceDetector from "../../../../hooks/deviceDetector";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Close, CloudDownloadOutlined, Delete } from "@mui/icons-material";
import { useFileService } from "../../../../hooks/api/files";
import { useAlert } from "../../../../hooks/alert";

export const FileUpload = ({
  appointmentId,
  toggleFiles,
}: {
  appointmentId: number;
  toggleFiles: () => void;
}) => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const device = useDeviceDetector();
  const [path, setPath] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const { postCreate, getFiles, getFile, deleteFile } = useFileService();
  const [fileList, setFileList] = useState<FileResponseDto[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { error } = useAlert();

  const handleFiles = async (id: number) => {
    getFiles(id)
      .then((files) => {
        if (files) {
          setFileList(files);
        } else {
          error("Não foi possivel encontrar os arquivos");
        }
      })
      .catch((err) => {
        console.log(err);
        error("Não foi possivel encontrar os arquivos");
      });
  };

  const selectFile = () => {
    const inputFile = document.getElementById("input-selectFile");
    if (!inputFile) return;
    inputFile.click();
  };

  const startDownload = (file: FileResponseDto) => {
    getFile(file.id)
      .then(async (fileString) => {
        const a = document.createElement("a"); //Create <a>
        a.href = `data:${file.mime_type};base64,` + fileString; //File Base64 Goes here
        a.download = file.display_name; //File name Here
        a.click(); //Downloaded file
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (!file) {
      return;
    }
    const { name } = file;
    const body = new FormData();
    body.append("url", name);
    body.append("appointmentId", String(appointmentId));
    body.append("file", file);

    const data = { body };
    postCreate(data)
      .then(() => {
        handleFiles(appointmentId);
        setFile(undefined);
        setVisible(false);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  const deleteFileOnList = (id: number) => {
    return deleteFile(id)
      .then(() => {
        return handleFiles(appointmentId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleFiles(appointmentId);
  }, []);

  useEffect(() => {
    console.log(file);
    if (file) {
      handleSubmit();
    }
  }, [file]);

  return (
    <div
      style={{
        position: "absolute",
        top: device === "desktop" ? 8 : 70,
        right: device === "desktop" ? 8 : 10,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundColor: "#fff",
        alignItems: "center",
        height: device === "desktop" ? windowHeight - 36 : windowHeight * 0.8,
        width: device === "desktop" ? windowWidth * 0.3 : windowWidth - 40,
        padding: 10,
        zIndex: 101,
        border: "1px solid",
        borderRadius: "5px",
        borderColor: "#DDD",
      }}
    >
      <div style={{ position: "absolute", top: 18, right: 8 }}>
        <IconButton onClick={toggleFiles}>
          <Close />
        </IconButton>
      </div>
      <div style={{ width: "100%" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileList.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.display_name}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => startDownload(row)}>
                    <CloudDownloadOutlined />
                  </IconButton>
                  <IconButton onClick={() => deleteFileOnList(row.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div>
        <Button
          variant="contained"
          onClick={selectFile}
          sx={{ height: 50, borderRadius: 25 }}
        >
          ENVIAR DOCUMENTO
        </Button>
        <input
          id="input-selectFile"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => {
            if (!e.target.files) {
              return;
            }
            setFile(e.target.files[0]);
          }}
        />
      </div>
    </div>
  );
};
