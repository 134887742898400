import {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Socket } from "socket.io-client";
import { MessageDtoResponse } from "../../../../hooks/api/Appointment/interfaces/appointmentMessage.response";
import { useAppointmentService } from "../../../../hooks/api/Appointment";
import { useWebhook } from "../../../../hooks/webhook";
import AuthContext from "../../../../context/auth";
import { useAlert } from "../../../../hooks/alert";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../../hooks/windowSize";
import useDeviceDetector from "../../../../hooks/deviceDetector";
import { Typography } from "@mui/material";
import { MessageAppointmentUI } from "./messageAppointment";

export const MessagesAppointmentContainer = ({
  appointmentId,
  toggleMessages,
  verifyMessages,
}: {
  appointmentId: number;
  toggleMessages: () => void;
  verifyMessages: (value: boolean) => void;
}) => {
  const [socket, setSocket] = useState<Socket>();
  const [messages, setMessages] = useState<MessageDtoResponse[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [userIdLogged, setUserIdLogged] = useState<number>(0);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const device = useDeviceDetector();
  const ref = useRef(null);

  //const isFocused = useIsFocused();
  const { error } = useAlert();
  const { getUserData } = useContext(AuthContext);
  const { getAppointmentMessages, createAppointmentMessages } =
    useAppointmentService();
  const { getSocket } = useWebhook({ room: `room-${appointmentId}` });
  const [isCopied, setCopied] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleFirstFetch = async () => {
    getAppointmentMessages(appointmentId)
      .then((data) => {
        let listMessages = data;
        console.log("data unique", data);
        setMessages(listMessages);
      })
      .catch((err) => {
        console.log("Erro ao recuperar as mensagens", err);
        error("Houve um erro ao recuperar as mensagens");
      });
  };

  const handleSocketConnection = useCallback(async () => {
    const socket = await getSocket();
    setSocket(socket);
    socket.on("connect", () => {
      console.log("connectou");
      verifyMessages(false);
    });
    socket.on("receiveMessage", (message) => {
      console.log(`receiveMessage:`, JSON.stringify(message));
      verifyMessages(true);
      const newMessage = message as MessageDtoResponse;
      if (!messages.find((m) => m.id === newMessage.id)) {
        setMessages((messages) => [...messages, newMessage]);
      }
    });
    socket.on("room", (message) => {
      console.log(`room:`, JSON.stringify(message));
    });
  }, [socket]);

  const handleComponentDidMount = useCallback(async () => {
    const user = await getUserData();
    setUserIdLogged(user.id);

    await handleFirstFetch()
      .then((response) => {})
      .catch((error) => {
        console.log();
      });
    await handleSocketConnection();
  }, [messages, socket]);

  const findAndOpenLink = (message: string) => {
    const parts = message.split(" ");
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].match(/\b(?:https?|ftp):\/\/\S+/)) {
        const a = document.createElement("a"); //Create <a>
        a.href = parts[i]; //Set Link
        a.target = "_blank"; //Set to open in a new page
        a.click(); //Open Link
      }
    }
  };

  const copyText = async (message: string) => {
    navigator.clipboard.writeText(message);
    setCopied(true);
  };

  const handleSendMessage = useCallback(async () => {
    console.log(`handleSendMessage:`, newMessage);
    if (!newMessage) return;

    socket?.emit("sendMessage", {
      message: newMessage,
      userId: userIdLogged,
      appointmentId,
    });

    console.log("message sent");

    setTimeout(function () {
      handleFirstFetch();
    }, 1000);

    setNewMessage("");
  }, [newMessage, userIdLogged]);

  const handleComponentDidUnmount = () => {
    setMessages([]);
    socket?.disconnect();
    setSocket(undefined);
  };

  useEffect(() => {
    handleComponentDidMount();
    console.log("element has focus");
    return () => {
      handleComponentDidUnmount();
      console.log("element does NOT have focus");
    };
  }, []);

  const messagesMemo = useMemo(() => {
    return messages.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }, [messages]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(function () {
        setCopied(false);
      }, 800);
    }
  }, [isCopied]);

  useEffect(() => {
    console.log(newMessage);
  }, [newMessage]);

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        top: device === "desktop" ? 8 : 70,
        right: device === "desktop" ? 8 : 10,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundColor: "#fff",
        alignItems: "center",
        height: device === "desktop" ? windowHeight - 56 : windowHeight * 0.8,
        width: device === "desktop" ? windowWidth * 0.3 : windowWidth - 40,
        padding: 10,
        zIndex: 101,
        border: "1px solid",
        borderRadius: "5px",
        borderColor: "#DDD",
      }}
    >
      {isCopied && (
        <div
          style={{
            backgroundColor: "#10a8bf",
            width: "100%",
            padding: 10,
            borderRadius: 20,
            textAlign: "center",
            top: 10,
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            O texto foi copiado.
          </Typography>
        </div>
      )}
      <MessageAppointmentUI
        {...{
          newMessage,
          setNewMessage,
          messages: messagesMemo,
          handleSendMessage,
          userIdLogged,
          findAndOpenLink,
          copyText,
          toggleMessages,
          handleKeyDown,
        }}
      />
    </div>
  );
};
