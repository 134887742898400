import { Controller, FieldValues } from "react-hook-form";
import { DatePickerProps } from "./interfaces/DatePickerProps";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormHelperText } from "@mui/material";
import dayjs from "dayjs";

export const CustomDatePicker = <TFieldValues extends FieldValues>({
  id,
  name,
  label,
  disabled = false,
  control,
  validationMessage,
}: DatePickerProps<TFieldValues>) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            name={id}
            value={dayjs(value)}
            onChange={(date) => onChange(dayjs(date).format("YYYY-MM-DD"))}
            label={label}
            disabled={disabled || control?._formState.isSubmitting}
            slotProps={{ textField: { fullWidth: true, id: id } }}
            format="DD/MM/YYYY"
          />
        )}
      />
      {validationMessage && (
        <FormHelperText style={{ color: "#D32F2F" }}>
          {validationMessage}
        </FormHelperText>
      )}
    </>

    /*<TextField
      id={id}
      color='secondary'
      fullWidth
      variant='outlined'
      {...register}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      error={!!validationMessage}
      helperText={validationMessage}
      autoComplete='off'
      disabled={disabled || control?._formState.isSubmitting}
      placeholder={placeholder}
      type={type}
    />*/
  );
};
