import { TextField } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { InputTextProps } from "./interfaces/InputTextProps";
import { IMaskInput } from "react-imask";
import React from "react";

export const MaskedInput = <TFieldValues extends FieldValues>({
  id,
  label,
  register,
  disabled = false,
  placeholder,
  validationMessage = "",
  type = "text",
  control,
  mask,
}: InputTextProps<TFieldValues>): React.ReactElement => {
  return (
    <TextField
      id={id}
      color="primary"
      fullWidth
      variant="outlined"
      {...register}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      error={!!validationMessage}
      helperText={validationMessage}
      autoComplete="off"
      disabled={disabled || control?._formState.isSubmitting}
      placeholder={placeholder}
      type={type}
      InputProps={{
        inputComponent: IMaskInput,
        inputProps: {
          mask: mask,
          overwrite: true,
        },
      }}
    />
  );
};
