import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserFormInputs } from "./interfaces/UserFormInputs";
import { UsersFormUI } from "./usersForm.ui";
import { makeRequestBody } from "./utils/makeRequestBody";
import { userEmpty } from "./utils/user.empty";
import { schema } from "./validations/validation";
import { usePatientService } from "../../hooks/api/Patient";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert } from "../../hooks/alert";
import { ApplicationRoutes } from "../../utils/navigation/applicationRoutes";
import { useLoginService } from "../../hooks/api/Login";
import { useAuth } from "../../hooks/auth";
import { LoginRequestBody } from "../../hooks/api/Login/interfaces/login.request";
import { useOptionService } from "../../hooks/api/options";
import { OptionResponse } from "../../hooks/api/options/interfaces/option.response";

export const UserFormContainer = () => {
  //const [isLoadingGetData, setIsLoadingGetData] = useState(false);
  const { getCity, getOptions } = useOptionService();
  const { postCreate, getByPhone } = usePatientService();
  const navigate = useNavigate();
  const formMethods = useForm<UserFormInputs>({
    defaultValues: userEmpty,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const { success, error } = useAlert();
  const { postLogin } = useLoginService();
  const { signIn } = useAuth();
  const [isGettingState, setIsGettingState] = useState(true);
  const [isGettingCity, setIsGettingCity] = useState(true);
  const [stateOptions, setStateOptions] = useState<OptionResponse[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionResponse[]>([]);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
    setValue,
    control,
    watch,
  } = formMethods;

  useEffect(() => {
    getOptions().then((options) => {
      setStateOptions(options);
      setIsGettingState(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsGettingCity(true);
    if (
      watch("state") !== undefined &&
      watch("state") !== null &&
      watch("state") !== ""
    ) {
      getCity(watch("state")).then((options) => {
        setCityOptions(options);
        setIsGettingCity(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("state")]);

  /*useEffect(() => {
    if (id === 'new') return;
    setIsLoadingGetData(true);
    getUser(Number(id))
      .then((result) => {
        setValue('name', result.name);
        setValue('phone', result.phone);
        setValue('email', result.email);
      })
      .finally(() => {
        setIsLoadingGetData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);*/

  useEffect(() => {
    console.log(watch("phone"));
  }, [watch("phone")]);

  const onSubmit = handleSubmit(async (data: UserFormInputs) => {
    const password = Math.random().toString(36).slice(-10);
    const body = makeRequestBody(data, password);
    postCreate({ body })
      .then((res) => {
        const body: LoginRequestBody = {
          phone: data.phone,
          password: password,
        };
        postLogin({ body }).then((res) => {
          signIn(res.accessToken, res.user);
          success("Dados usuário salvos com sucesso!");
          navigate(ApplicationRoutes.DOCTORS);
        });
      })
      .catch((err) => {
        console.error(err);
        error(err.response.data.message);
      });
  });

  return (
    <UsersFormUI
      onSubmit={onSubmit}
      register={register}
      errors={errors}
      isLoading={isLoading}
      control={control}
      cityOptions={cityOptions}
      isGettingCity={isGettingCity}
      isGettingState={isGettingState}
      stateOptions={stateOptions}
    />
  );
};
