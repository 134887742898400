import { Grid, Typography, useTheme } from "@mui/material";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { InputText } from "../../components/input";
import { MaskedInput } from "../../components/maskedInput";
import { LoadingButton } from "../../components/loadingButton";
import { UserFormInputs } from "./interfaces/UserFormInputs";
import { useInputsStyle } from "../../hooks/inputsStyle";
import { CustomDatePicker } from "../../components/datePicker";
import { Dropdown } from "../../components/select";
import { OptionResponse } from "../../hooks/api/options/interfaces/option.response";
import { NumberInput } from "../../components/numberInput";

export const UsersFormUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
  control,
  cityOptions,
  isGettingCity,
  isGettingState,
  stateOptions,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<UserFormInputs>;
  errors: FieldErrors<UserFormInputs>;
  isLoading: boolean;
  control: Control<UserFormInputs>;
  stateOptions: OptionResponse[];
  cityOptions: OptionResponse[];
  isGettingCity: boolean;
  isGettingState: boolean;
}) => {
  const theme = useTheme();
  const { container, inputsSections, normalInput, buttonSection, buttonSize } =
    useInputsStyle({ theme });

  return (
    <Grid {...container}>
      <Grid item xs={12}>
        <Grid item xs={12} mb={3}>
          <Typography variant="h4" component="h1" color="secondary">
            Criar Usuário
          </Typography>
        </Grid>
        <form onSubmit={onSubmit}>
          <Grid {...inputsSections}>
            <Grid {...normalInput}>
              <InputText
                id="input-name"
                label="Nome"
                validationMessage={errors.name?.message}
                register={register("name")}
                disabled={isLoading}
              />
            </Grid>
            <Grid {...normalInput}>
              {/*<InputText
                  label='Telefone'
                  validationMessage={errors.phone?.message}
                  register={register('phone')}
                  disabled={isLoading}
              />*/}
              <MaskedInput
                id="input-phone"
                label="Telefone"
                validationMessage={errors.phone?.message}
                register={register("phone")}
                disabled={isLoading}
                mask={["(00) 0000-0000", "(00) 00000-0000"]}
                control={control}
              />
            </Grid>
            <Grid {...normalInput}>
              <InputText
                id="input-email-create"
                label="Email"
                validationMessage={errors.email?.message}
                register={register("email")}
                disabled={isLoading}
                autocomplete="one-time-code"
              />
            </Grid>
          </Grid>
          <Grid {...inputsSections}>
            <Grid {...normalInput}>
              <CustomDatePicker
                id="input-birthdate"
                label="Data de nascimento"
                name="birthdate"
                disabled={isLoading}
                control={control}
                validationMessage={errors.birthdate?.message}
              />
            </Grid>
            <Grid {...normalInput}>
              <Dropdown
                id="dropdown-type"
                control={control}
                label="Genero"
                name="gender"
                disabled={isLoading}
                key="gender"
                validationMessage={errors.gender?.message}
                options={[
                  { value: "male", label: "Masculino" },
                  { value: "female", label: "Feminino" },
                  { value: "other", label: "Outro" },
                ]}
              />
            </Grid>
            <Grid {...normalInput}>
              <InputText
                id="input-sus-number"
                label="Cartão SUS"
                validationMessage={errors.susNumber?.message}
                register={register("susNumber")}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
          <Grid {...inputsSections}>
            <Grid {...normalInput}>
              <Dropdown
                id="dropdown-region"
                control={control}
                label="Região"
                name="region"
                disabled={isLoading}
                key="region"
                validationMessage={errors.region?.message}
                options={[
                  { value: "south", label: "Sul" },
                  { value: "southeast", label: "Sudeste" },
                  { value: "centerwest", label: "Centro-Oeste" },
                  { value: "north", label: "Norte" },
                  { value: "northeast", label: "Nordeste" },
                ]}
              />
            </Grid>
            <Grid {...normalInput}>
              <Dropdown
                id="dropdown-state"
                control={control}
                label="Estado"
                name="state"
                disabled={isLoading || isGettingState}
                key="state"
                validationMessage={errors.state?.message}
                options={stateOptions}
              />
            </Grid>
            <Grid {...normalInput}>
              <Dropdown
                id="dropdown-city"
                control={control}
                label="Cidade"
                name="city"
                disabled={
                  isLoading || cityOptions.length === 0 || isGettingCity
                }
                key="city"
                validationMessage={errors.city?.message}
                options={cityOptions}
              />
            </Grid>
          </Grid>
          <Grid {...inputsSections}>
            <Grid {...normalInput}>
              <InputText
                id="input-labor"
                label="Profissão"
                validationMessage={errors.labor?.message}
                register={register("labor")}
                disabled={isLoading}
              />
            </Grid>
            <Grid {...normalInput}>
              <NumberInput
                id="input-income"
                label="Renda mensal"
                validationMessage={errors.income?.message}
                name="income"
                disabled={isLoading}
                prefix="R$ "
                control={control}
              />
            </Grid>

            {/* <Grid {...normalInput}>
              <InputText
                id="input-password-create"
                label="Senha"
                validationMessage={errors.password?.message}
                register={register("password")}
                disabled={isLoading}
                type="password"
                autocomplete="one-time-code"
              />
            </Grid> */}
          </Grid>
          {/* <Grid {...inputsSections}>
            <Grid {...normalInput}>
              <InputText
                id="input-confirmPassword"
                label="Confirmar senha"
                validationMessage={errors.confirmPassword?.message}
                register={register("confirmPassword")}
                disabled={isLoading}
                type="password"
              />
            </Grid>
          </Grid> */}
          <Grid {...buttonSection}>
            <Grid {...buttonSize}>
              <LoadingButton
                id="button-submit"
                label={"Cadastrar"}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
