import { useApi } from "..";
import { PatientCreateRequestDto } from "./interfaces/patientCreate.request.dto";
import { PatientEntity } from "./interfaces/user.response";

export const usePatientService = () => {
  const { post, get } = useApi();

  const postCreate = ({ body }: PatientCreateRequestDto) => {
    return post("/patient/create", body);
  };

  const getByPhone = (phone: string) => {
    return get<PatientEntity>("/patient/find-phone?phone=" + phone);
  };

  return {
    postCreate,
    getByPhone,
  };
};
