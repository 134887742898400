import { JSX, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import CashIcon from "@mui/icons-material/MoneySharp";
import UsersGroupIcon from "@mui/icons-material/PeopleAltSharp";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformationSharp";
import MedicalIcon from "@mui/icons-material/LocalHospital";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Groups from "@mui/icons-material/Groups";

import { LayoutUI } from "./layout.ui";
import { AppBarMenuSetting } from "../appBar/interfaces/AppBarMenuSetting";
import { useAuth } from "../../hooks/auth";
import { ApplicationRoutes } from "../../utils/navigation/applicationRoutes";

export const LayoutContainer = ({ children }: { children: JSX.Element }) => {
  const [userName, setUserName] = useState("");
  const [isOpenSettings, setOpenSettings] = useState(false);
  const [menuSettings, setMenuSettings] = useState<AppBarMenuSetting[]>([]);

  const { signOut, getUserData, isSigned } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    //TODO call singout endpoint
    signOut();
    navigate(ApplicationRoutes.SIGN_IN);
  };

  const fetchMenuSettings = () => {
    const menuSettings: AppBarMenuSetting[] = [
      {
        name: "Sair",
        handleClick: handleSignOut,
      },
    ];

    setMenuSettings(menuSettings);
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  useEffect(() => {
    fetchMenuSettings();
    const { name } = getUserData();
    setUserName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutUI
      appBarProps={{
        userName,
        isOpenSettings,
        menuSettings,
        handleOpenSettings,
        handleCloseSettings,
      }}
    >
      {children}
    </LayoutUI>
  );
};
