import wsIOClient, { Socket } from "socket.io-client";

export const useWebhook = ({ room }: { room: string }) => {
  //const baseUrl = "https://c7c5-188-250-50-16.ngrok-free.app";
  //const baseUrl = "http://localhost:3097";
  const baseUrl = "https://api.vivasesaude.com.br";
  //secure socket connection to api
  //const baseUrl = "https://157.245.82.171:3097";

  const getSocket = async (): Promise<Socket> => {
    const io = wsIOClient(`${baseUrl}`, {
      extraHeaders: {
        room,
      },
    });
    return io;
  };

  return { getSocket };
};
