import { useApi } from "..";
import { MedicalRecordsEntityResponse } from "./interfaces/medicalRecords.response";
import { MedicalRecordsCreateRequest } from "./interfaces/medicalRecordsCreate.request";
import { OptionsResponse } from "./interfaces/options.response";

export const useMedicalRecordService = () => {
  const { post, get } = useApi();

  const postCreateMedicalRecord = ({ body }: MedicalRecordsCreateRequest) => {
    return post("/medical-record/create", body);
  };

  const getRecords = async (appointmentId: number) => {
    const { data } = await get<MedicalRecordsEntityResponse[]>(
      "/medical-record/find/" + appointmentId
    );
    return data;
  };

  const getRecordByAppointment = async (id: number) => {
    return get<MedicalRecordsEntityResponse>(
      "/medical-record/find-one-appointment/" + id
    ).then((response) => {
      return response.data;
    });
  };

  const getMedicine = async (term: string) => {
    const { data } = await get<OptionsResponse[]>(
      "/medicine/search?term=" + term
    );
    return data;
  };

  const getMedicineByNames = async (names: string[]) => {
    const { data } = await get<OptionsResponse[]>(
      `/medicine/name?names=${names.join(",")}`
    );
    return data;
  };

  const getComorbidities = async () => {
    const { data } = await get<OptionsResponse[]>("/comorbidity/find-all");
    return data;
  };

  return {
    postCreateMedicalRecord,
    getRecords,
    getMedicine,
    getComorbidities,
    getMedicineByNames,
    getRecordByAppointment,
  };
};
