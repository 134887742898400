import AgoraRtc, {
  LocalVideoTrack,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRTCClient,
  useRemoteUsers,
  useClientEvent,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-react";

import React, { createContext, useContext, useState, useEffect } from "react";
import { configType, config } from "./config/config";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, IconButton } from "@mui/material";
import {
  CallEnd,
  FlipCameraIos,
  InsertDriveFileOutlined,
  MessageOutlined,
  Mic,
  MicOff,
  SpeakerNotesOff,
  SpeakerNotesOffOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import useWindowDimensions from "../../hooks/windowSize";
import useDeviceDetector from "../../hooks/deviceDetector";
import { FileUpload } from "./components/FileUpload/fileUpload";
import { MessagesAppointment } from "./components/MessagesAppointment";
import { useAuth } from "../../hooks/auth";
import { UserInfoResponse } from "../../hooks/api/Login/interfaces/userInfo.response";
import { useAppointmentService } from "../../hooks/api/Appointment";
import { useDialog } from "../../hooks/dialog";
import { boolean } from "yup";

// Define the shape of the Agora context
interface AgoraContextType {
  localCameraTrack: ICameraVideoTrack | null;
  localMicrophoneTrack: IMicrophoneAudioTrack | null;
  children: React.ReactNode;
}

// Create the Agora context
const AgoraContext = createContext<AgoraContextType | null>(null);

// AgoraProvider component to provide the Agora context to its children
export const AgoraProvider: React.FC<AgoraContextType> = ({
  children,
  localCameraTrack,
  localMicrophoneTrack,
}) => (
  <AgoraContext.Provider
    value={{ localCameraTrack, localMicrophoneTrack, children }}
  >
    {children}
  </AgoraContext.Provider>
);

// Custom hook to access the Agora context
export const useAgoraContext = () => {
  const context = useContext(AgoraContext);
  if (!context)
    throw new Error("useAgoraContext must be used within an AgoraProvider");
  return context;
};

export const VideoCallContainer = () => {
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const { putFinishAppointment } = useAppointmentService();
  const { handleCloseDialog, handleOpenDialog } = useDialog();
  const { getUserData } = useAuth();
  const [user, setUser] = useState<UserInfoResponse>();
  // Retrieve local camera and microphone tracks and remote users
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const device = useDeviceDetector();
  const { id } = useParams();
  const agoraEngine = useRTCClient();
  const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
  const { isLoading: isLoadingMic, localMicrophoneTrack } =
    useLocalMicrophoneTrack();
  const remoteUsers = useRemoteUsers();
  const [role, setRole] = useState("host"); // Default role is host
  const [localVideoMuted, setLocalVideoMuted] = useState<boolean>(false);
  const [localAudioMuted, setLocalAudioMuted] = useState<boolean>(false);
  const [remoteVideoMuted, setRemoteVideoMuted] = useState<boolean>(false);
  //const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [cameraMode, setCameraMode] = useState<"environment" | "user">("user");
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [showMessages, setShowMessages] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchUserData = () => {
    const user = getUserData();
    setUser(user);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const toggleFiles = () => {
    setShowMessages(false);
    setShowFiles(!showFiles);
  };

  const toggleMessages = () => {
    setShowFiles(false);
    setShowMessages(!showMessages);
  };
  /*useEffect(() => {
    AgoraRtc.getCameras().then((cameras) => {
      setDevices(cameras);
    });
  }, []);*/

  // Publish local tracks
  usePublish([localMicrophoneTrack, localCameraTrack]);

  // Join the Agora channel with the specified configuration
  useJoin({
    appid: config.appId,
    channel: String(id),
    token: null,
    uid: config.uid,
  });

  useClientEvent(agoraEngine, "user-joined", (user) => {
    console.log("The user", user.uid, " has joined the channel");
  });

  useClientEvent(agoraEngine, "user-left", (user) => {
    console.log("The user", user.uid, " has left the channel");
  });

  useClientEvent(agoraEngine, "user-published", (user, mediaType) => {
    if (mediaType === "video") {
      setRemoteVideoMuted(false);
    }
  });

  useClientEvent(agoraEngine, "user-unpublished", (user, mediaType) => {
    console.log(user, mediaType);
    if (mediaType === "video") {
      setRemoteVideoMuted(true);
      console.log("Video");
    } else {
      console.log("Audio");
    }
  });

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
    if (event.target.value === "host") {
      agoraEngine
        .setClientRole("host")
        .then(() => {
          // Your code to handle the resolution of the promise
          console.log("Client role set to host successfully");
        })
        .catch((error) => {
          // Your code to handle any errors
          console.error("Error setting client role:", error);
        });
    } else {
      agoraEngine
        .setClientRole("audience")
        .then(() => {
          // Your code to handle the resolution of the promise
          console.log("Client role set to host successfully");
        })
        .catch((error) => {
          // Your code to handle any errors
          console.error("Error setting client role:", error);
        });
    }
  };

  useEffect(() => {
    return () => {
      localCameraTrack?.close();
      localMicrophoneTrack?.close();
    };
  }, []);

  useEffect(() => {
    localMicrophoneTrack?.setMuted(localAudioMuted);
  }, [localAudioMuted]);

  useEffect(() => {
    localCameraTrack?.setMuted(localVideoMuted);
  }, [localVideoMuted]);

  useEffect(() => {
    console.log("dimentions: ", windowHeight, windowWidth);
  }, [windowHeight, windowWidth]);

  const leave = () => {
    if (user && user.type === "Doctor") {
      handleOpenDialog("Atenção", "Deseja finalizar a chamada?", [
        {
          label: "Não",
          action: handleCloseDialog,
        },
        {
          label: "Sim",
          isCancel: true,
          action: () => {
            putFinishAppointment(Number(id)).then(() => {
              handleCloseDialog();
              localCameraTrack?.close();
              localMicrophoneTrack?.close();
              navigate("/");
            });
          },
        },
      ]);
    } else {
      localCameraTrack?.close();
      localMicrophoneTrack?.close();
      navigate("/print/" + id);
    }
  };

  const changeDevice = () => {
    if (cameraMode === "environment") {
      localCameraTrack?.setDevice({ facingMode: "user" });
      setCameraMode("user");
    } else {
      localCameraTrack?.setDevice({ facingMode: "environment" });
      setCameraMode("environment");
    }
  };

  // Check if devices are still loading
  const deviceLoading = isLoadingMic || isLoadingCam;
  if (deviceLoading) return <div>Loading devices...</div>;

  // Render the AgoraProvider and associated UI components
  return (
    <AgoraProvider
      localCameraTrack={localCameraTrack}
      localMicrophoneTrack={localMicrophoneTrack}
    >
      <div
        id="videos"
        style={{
          position: "absolute",
          top: device === "desktop" ? 8 : 0,
          left: device === "desktop" ? 8 : 0,
          display: "flex",
          flexDirection: device === "desktop" ? "row" : "column",
          margin: 0,
          padding: 0,
        }}
      >
        {/* Render the local video track */}
        {!localVideoMuted && (
          <div
            className="vid"
            style={{
              height:
                device === "desktop" ? windowHeight * 0.85 : windowHeight * 0.5,
              width:
                device === "desktop"
                  ? showFiles || showMessages
                    ? windowWidth * 0.35 - 8
                    : windowWidth * 0.5 - 8
                  : windowWidth,
              margin: 0,
              padding: 0,
            }}
          >
            <LocalVideoTrack track={localCameraTrack} play={!localVideoMuted} />
          </div>
        )}
        {localVideoMuted && (
          <div
            className="vid"
            style={{
              height:
                device === "desktop" ? windowHeight * 0.85 : windowHeight * 0.5,
              width:
                device === "desktop"
                  ? showFiles || showMessages
                    ? windowWidth * 0.35 - 8
                    : windowWidth * 0.5 - 8
                  : windowWidth,
              margin: 0,
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#000",
            }}
          >
            <VideocamOffOutlined sx={{ fontSize: 70, color: "#fff" }} />
          </div>
        )}
        {/* Render remote users' video and audio tracks */}
        {!remoteVideoMuted &&
          remoteUsers.length > 0 &&
          remoteUsers.map((remoteUser) => (
            <div
              className="vid"
              style={{
                height:
                  device === "desktop"
                    ? windowHeight * 0.85
                    : windowHeight * 0.5,
                width:
                  device === "desktop"
                    ? showFiles || showMessages
                      ? windowWidth * 0.35 - 8
                      : windowWidth * 0.5 - 8
                    : windowWidth,
                margin: 0,
                padding: 0,
              }}
              key={remoteUser.uid}
            >
              <RemoteUser user={remoteUser} playVideo={true} playAudio={true} />
            </div>
          ))}
        {remoteVideoMuted && (
          <div
            className="vid"
            style={{
              height:
                device === "desktop" ? windowHeight * 0.85 : windowHeight * 0.5,
              width:
                device === "desktop"
                  ? showFiles || showMessages
                    ? windowWidth * 0.35 - 8
                    : windowWidth * 0.5 - 8
                  : windowWidth,
              margin: 0,
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#000",
            }}
          >
            <VideocamOffOutlined sx={{ fontSize: 70, color: "#fff" }} />
          </div>
        )}
      </div>
      {device === "mobile" && (
        <div
          style={{
            position: "absolute",
            top: windowHeight * 0.05,
            right: windowWidth * 0.025,
            zIndex: 100,
          }}
        >
          <IconButton onClick={changeDevice} sx={{ bgcolor: "#0A6172" }}>
            <FlipCameraIos sx={{ color: "#fff" }} />
          </IconButton>
        </div>
      )}
      <div
        style={{
          width:
            device === "desktop"
              ? showFiles || showMessages
                ? windowWidth * 0.4
                : windowWidth * 0.56
              : windowWidth * 0.9,
          position: "absolute",
          bottom: device === "desktop" ? 60 : 20,
          left: device === "desktop" ? windowWidth * 0.22 : windowWidth * 0.05,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          zIndex: 100,
        }}
      >
        <div>
          <IconButton
            sx={{
              bgcolor: localVideoMuted ? "#fff" : "#0A6172",
            }}
            onClick={() => setLocalVideoMuted(!localVideoMuted)}
          >
            {localVideoMuted ? (
              <VideocamOffOutlined sx={{ color: "#0A6172" }} />
            ) : (
              <VideocamOutlined sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </div>
        <div>
          <IconButton
            sx={{
              bgcolor: localAudioMuted ? "#fff" : "#0A6172",
            }}
            onClick={() => setLocalAudioMuted(!localAudioMuted)}
          >
            {localAudioMuted ? (
              <MicOff sx={{ color: "#0A6172" }} />
            ) : (
              <Mic sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </div>
        <div>
          <IconButton onClick={leave} sx={{ bgcolor: "#CC0000" }}>
            <CallEnd sx={{ color: "#fff", fontSize: 30 }} />
          </IconButton>
        </div>
        <div>
          <Badge color="error" variant="dot" invisible={showIcon}>
            <IconButton
              onClick={() => setShowMessages(!showMessages)}
              sx={{ bgcolor: showMessages ? "#fff" : "#0A6172" }}
            >
              {!showMessages && <MessageOutlined sx={{ color: "#fff" }} />}
              {showMessages && (
                <SpeakerNotesOffOutlined sx={{ color: "#0A6172" }} />
              )}
            </IconButton>
          </Badge>
        </div>
        <div>
          <IconButton
            onClick={() => setShowFiles(!showFiles)}
            sx={{ bgcolor: showFiles ? "#fff" : "#0A6172" }}
          >
            <InsertDriveFileOutlined
              sx={{ color: showFiles ? "#0A6172" : "#fff" }}
            />
          </IconButton>
        </div>
      </div>
      {showFiles && id && (
        <FileUpload
          {...{
            appointmentId: Number(id),
            toggleFiles: toggleFiles,
          }}
        />
      )}
      {showMessages && id && (
        <MessagesAppointment
          {...{
            appointmentId: Number(id),
            toggleMessages: toggleMessages,
            verifyMessages: (value) => {
              //console.log("valor", value);
              setShowIcon(value);
            },
          }}
        />
      )}
    </AgoraProvider>
  );
};

// Export the AgoraManager component as the default export
export default VideoCallContainer;
