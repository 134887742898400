import { useEffect, useState } from "react";
import "./index.scss";
import { Button, Typography } from "@mui/material";
import ReceiptPDF from "../../components/receiptPDF";
import { useNavigate, useParams } from "react-router-dom";
import { useMedicalRecordService } from "../../hooks/api/medicalRecord";
import { MedicalRecordsEntityResponse } from "../../hooks/api/medicalRecord/interfaces/medicalRecords.response";
import { useLoginService } from "../../hooks/api/Login";
import { ApplicationRoutes } from "../../utils/navigation/applicationRoutes";
import { useAuth } from "../../hooks/auth";

const FilePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [medicalRecord, setMedicalRecord] =
    useState<MedicalRecordsEntityResponse>();
  const { id } = useParams();
  const { signOut } = useAuth();
  const { getRecordByAppointment } = useMedicalRecordService();
  const { putLogout } = useLoginService();
  const navigate = useNavigate();

  const loadItems = () => {
    setIsLoading(true);
    if (id) {
      getRecordByAppointment(Number(id))
        .then((response) => {
          setMedicalRecord(response);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const printDoc = () => {
    window.print();
  };

  const backToList = () => {
    let cabin = localStorage.getItem("cabin");
    if (cabin != null && cabin != "" && cabin != undefined){
      putLogout().then(() => {
        signOut();
        navigate(ApplicationRoutes.APPOINTMENT_LIST_PORTUGUESE + "/cabine/" + cabin);
      });
    }else{
      putLogout().then(() => {
        signOut();
        navigate(ApplicationRoutes.APPOINTMENT_LIST);
      });
    }    
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Button
          id="btn-exit"
          onClick={backToList}
          variant="contained"
          color="warning"
        >
          Voltar para a lista
        </Button>
        {medicalRecord && !isLoading && (
          <div style={{ width: "100%" }}>
            <Button
              id="btn-print"
              onClick={printDoc}
              variant="contained"
              color="primary"
              /*disabled={
                !items?.some(
                  (item) =>
                    item.status === StatusItemBudget.CONFIRMED ||
                    item.status === StatusItemBudget.SENT
                )
              }*/
            >
              Imprimir
            </Button>
            <div className="printable">
              <ReceiptPDF record={medicalRecord} />
            </div>
          </div>
        )}
        {!medicalRecord && (
          <div>
            <Typography
              style={{ color: "#000" }}
              variant="h4"
              component="h1"
              color="secondary"
            >
              Receita não encontrada!
            </Typography>
          </div>
        )}
        {isLoading && <div>Aguarde enquanto o pdf é gerado!</div>}
      </div>
    </>
  );
};

export default FilePage;
