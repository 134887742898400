import { useApi } from "..";
import { LoginRequest } from "./interfaces/login.request";
import { LoginResponse } from "./interfaces/login.response";

export const useLoginService = () => {
  const { post, put } = useApi();

  const postLogin = async ({ body }: LoginRequest) => {
    const { data } = await post<LoginResponse>("/user/login", body);
    return data;
  };

  const postAutoLogin = async ({ body }: { body: { phone: string } }) => {
    const { data } = await post<LoginResponse>("/user/auto-login", body);
    return data;
  };

  const putLogout = async () => {
    return put("/user/logout");
  };

  return {
    postLogin,
    putLogout,
    postAutoLogin,
  };
};
