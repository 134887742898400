import { OptionResponse } from "./interfaces/option.response";
import { AdressResponse } from "./interfaces/adress.response";
import { useApi } from "..";

export const useOptionService = () => {
  const { get } = useApi();

  const getOptions = async () => {
    const { data } = await get<OptionResponse[]>("/options/states");
    return data;
  };

  const getCity = async (uf: string) => {
    const { data } = await get<OptionResponse[]>("/options/city/" + uf);
    return data;
  };

  const getAdress = async (cep: string) => {
    const { data } = await get<AdressResponse>("/options/address/" + cep);
    return data;
  };

  return {
    getOptions,
    getCity,
    getAdress,
  };
};
