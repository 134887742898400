import { TextField } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { InputTextProps } from "./interfaces/InputTextProps";

export const InputText = <TFieldValues extends FieldValues>({
  id,
  label,
  register,
  disabled = false,
  placeholder,
  validationMessage = "",
  type = "text",
  control,
  autocomplete = "off",
}: InputTextProps<TFieldValues>) => {
  return (
    <TextField
      id={id}
      color="primary"
      fullWidth
      variant="outlined"
      {...register}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      error={!!validationMessage}
      helperText={validationMessage}
      autoComplete={autocomplete}
      disabled={disabled || control?._formState.isSubmitting}
      placeholder={placeholder}
      type={type}
    />
  );
};
