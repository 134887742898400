import "./index.scss";
import { MedicalRecordsEntityResponse } from "../../hooks/api/medicalRecord/interfaces/medicalRecords.response";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { log } from "console";
import icpLogo from "../../assets/icp_brasil.png";
import QRCode from "react-qr-code";

function ReceiptPDF({ record }: { record: MedicalRecordsEntityResponse }) {
  useEffect(() => {
    console.log(record);
  }, []);

  //return datetime in format DD/MM/YYYY HH:mm
  const getCurrentDateTime = () => {
    const date = new Date()
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
  }

  return (
    <div className="pdfBox">
      <div className="print-outline"></div>
      <div className="page-one">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="page-header">
            <div className="top-table">
              <div className="top-table-header">
                <Typography>IDENTIFICAÇÃO DO EMITENTE</Typography>
              </div>
              <Typography
                sx={{ fontSize: 14, marginLeft: 2 }}
              >{`Nome Completo: ${record.appointment.doctor.user.name}`}</Typography>
              <div className="top-table-line">
                <Typography
                  sx={{ fontSize: 14 }}
                >{`CRM: ${record.appointment.doctor.crm}`}</Typography>
                <Typography
                  sx={{ fontSize: 14, marginLeft: 2 }}
                >{`UF: ${record.appointment.doctor.state}`}</Typography>
                <Typography
                  sx={{ fontSize: 14, marginLeft: 2 }}
                >{`N.º: ${record.appointment.doctor.address.substring(
                  record.appointment.doctor.address.lastIndexOf(",") + 1
                )}`}</Typography>
              </div>
              <Typography
                sx={{ fontSize: 14, marginLeft: 2 }}
              >{`Endereço completo: ${record.appointment.doctor.address}, ${record.appointment.doctor.neighborhood}`}</Typography>
              <Typography
                sx={{ fontSize: 14, marginLeft: 2 }}
              >{`Telefone: ${record.appointment.doctor.user.phone}`}</Typography>
              <div className="top-table-line">
                <Typography sx={{ fontSize: 14 }}>{`Cidade: ${
                  record.appointment.doctor.city[0].toUpperCase() +
                  record.appointment.doctor.city.substr(1)
                }`}</Typography>
                <Typography
                  sx={{ fontSize: 14, marginLeft: 2 }}
                >{`UF: ${record.appointment.doctor.state}`}</Typography>
              </div>
            </div>
            <div className="page-header-indicator">
              <Typography sx={{ marginBottom: 1 }}>1.ª VIA FARMACIA</Typography>
              <Typography>2.ª VIA PACIENTE</Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 40,
              marginTop: 15,
            }}
          >
            {record.recordMedicine.map((medicine) => (
              <Typography
                sx={{ fontSize: 14, marginTop: 2 }}
              >{`${medicine.medicine.name}: ${medicine.dosage}`}</Typography>
            ))}
          </div>
        </div>

        <div className="page-header">
          <div className="top-table">
            <div className="top-table-header">
              <Typography>IDENTIFICAÇÃO DO COMPRADOR</Typography>
            </div>
            <Typography
              sx={{ fontSize: 14, marginLeft: 2 }}
            >{`Nome: `}</Typography>
            <br />
            <div className="top-table-line">
              <Typography sx={{ fontSize: 14 }}>{`Ident: `}</Typography>
              <Typography
                sx={{ fontSize: 14, marginLeft: 13 }}
              >{`Org. Emissor: `}</Typography>
            </div>
            <Typography
              sx={{ fontSize: 14, marginLeft: 2 }}
            >{`End: `}</Typography>
            <br />
            <div className="top-table-line">
              <Typography sx={{ fontSize: 14 }}>{`Cidade: `}</Typography>
              <Typography
                sx={{ fontSize: 14, marginLeft: 15 }}
              >{`UF: `}</Typography>
            </div>
          </div>
          <div className="bottom-table">
            <div className="top-table-header">
              <Typography>IDENTIFICAÇÃO DO FORNECEDOR</Typography>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="top-table-line">
              <Typography sx={{ fontSize: 11, textDecoration: "overline" }}>
                ASSINATURA DO FARMACEUTICO
              </Typography>
              <Typography sx={{ fontSize: 11, marginLeft: 0.5 }}>
                DATA: ____/__/___
              </Typography>
            </div>
          </div>
        </div>

        <div className="page-header">
          <div className="top-table" style={{padding: 10, borderWidth: 0}}>
            <img src={icpLogo} style={{width: 250}}  />  
            <Typography
              sx={{ fontSize: 18, fontWeight: "bold" }}>
              Receituário de Controle Especial
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}>
              Receituário de Controle Especial assinado digitalmente por {record.appointment.doctor.user.name} em {getCurrentDateTime()},
              conforme MP n° 2.200-2/2001 e Resolução CFM n° 2.299/2021. <br />
              A assinatura digital deste documento poderá ser verificada em https://validar.iti.gov.br. <br />
              Acesse o documento em https://web.vivasesaude.com.br/receita/{record.appointment.hash}
            </Typography>
            
          </div>
          <div className="bottom-table" style={{borderWidth: 0}}>            
            <div className="top-table-line">
              <QRCode value={`https://web.vivasesaude.com.br/receita/${record.appointment.hash}`} size={150} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReceiptPDF;
