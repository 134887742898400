import { createContext } from 'react';

import { AlertContextProps } from './interfaces/AlertContextProps';

export const AlertContext = createContext<AlertContextProps>(
  {
    error: (message: string) => {
      window.alert(message)
    },
    warning: (message: string) => {
      window.alert(message)
    }
  } as AlertContextProps
);
