import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { AppointmentFormInputs } from "../interfaces/AppointmentFormInputs";
import { LoadingButton } from "../../../components/loadingButton";
import { SpecialtyEntityResponse } from "../../../hooks/api/Specialty/interfaces/specialtySearch.response";
import { ServiceHoursEntity } from "../../../hooks/api/ServiceHours/interfaces/serviceHours.response";
import { Dropdown } from "../../../components/select";
import dayjs from "dayjs";
import { HoursRadioGroup } from "./hoursRadioGroup";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export const AppointmentModal = ({
  onSubmit,
  control,
  visible,
  handleClose,
  errors,
  register,
  isLoading,
  dates,
  hours,
  setSpecialty,
  specialties,
  specialty,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  control: Control<AppointmentFormInputs>;
  visible: boolean;
  handleClose: () => void;
  register: UseFormRegister<AppointmentFormInputs>;
  errors: FieldErrors<AppointmentFormInputs>;
  isLoading: boolean;
  specialty: SpecialtyEntityResponse | undefined;
  setSpecialty: Dispatch<SetStateAction<SpecialtyEntityResponse | undefined>>;
  specialties: SpecialtyEntityResponse[];
  hours: ServiceHoursEntity[];
  dates: string[];
}) => {
  const theme = useTheme();

  return (
    <Modal open={visible} onClose={handleClose}>
      <Box sx={style}>
        <form onSubmit={onSubmit}>
          <div style={{ marginBottom: 15 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Especialidade
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="input-specialty"
                value={specialty?.id}
                label="Especialidade"
                onChange={(e) => {
                  setSpecialty(
                    specialties.find((item) => item.id === e.target.value)
                  );
                }}
              >
                <MenuItem
                    id={`input-specialty-0`}
                    key={""}
                    value={"Selecione"}
                  >
                  Selecione...
                </MenuItem>
                {specialties.map((specialty) => (
                  <MenuItem
                    id={`input-specialty-${specialty.id}`}
                    key={specialty.id}
                    value={specialty.id}
                  >
                    {specialty.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: 15 }}>
            <Dropdown
              id="dropdown-type"
              control={control}
              label="Data"
              name="date"
              disabled={isLoading}
              key="gender"
              validationMessage={errors.date?.message}
              options={dates.map((date) => {
                return {
                  label: dayjs(date).format("YYYY-MM-DD"),
                  value: date,
                };
              })}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <HoursRadioGroup {...{ control, hours, isLoading }} />
          </div>
          <div
            style={{
              marginTop: 20,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              id="button-submit"
              label="Atualizar"
              isLoading={isLoading}
            />
          </div>
        </form>
      </Box>
    </Modal>
  );
};
