import { PatientCreateRequestBody } from "../../../hooks/api/Patient/interfaces/patientCreate.request.dto";
import { convertToNumberOrUndefined } from "../../../utils/stringUtils";
import { UserFormInputs } from "../interfaces/UserFormInputs";

export const makeRequestBody = (
  obj: UserFormInputs,
  password: string
): PatientCreateRequestBody => {
  return {
    name: obj.name,
    phone: obj.phone,
    email: obj.email,
    password: password,
    confirmPassword: password,
    campaign: true,
    birthdate: obj.birthdate,
    city: obj.city,
    gender: obj.gender,
    labor: obj.labor,
    region: obj.region,
    state: obj.state,
    susNumber: obj.susNumber,
    income: obj.income,
  };
};
